import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { Printer, FileText, LogOut, List } from 'react-feather';

import { FormTextInput } from '../Components/FormTextInput';

import { TransitionContext } from '../Context/TransitionContext';
import { UserContext } from '../Context/UserContext';
import { NotificationContext } from '../Context/NotificationContext';

import { errorLog } from '../Helpers/errorLog';

import './Account.css';

const provinces = [
  { value: 'buenosAires', label: 'Buenos Aires' },
  { value: 'catamarca', label: 'Catamarca' },
  { value: 'caba', label: 'Ciudad Autónoma de Buenos Aires' },
  { value: 'chaco', label: 'Chaco' },
  { value: 'chubut', label: 'Chubut' },
  { value: 'cordoba', label: 'Córdoba' },
  { value: 'corrientes', label: 'Corrientes' },
  { value: 'entreRios', label: 'Entre Ríos' },
  { value: 'formosa', label: 'Formosa' },
  { value: 'jujuy', label: 'Jujuy' },
  { value: 'laPampa', label: 'La Pampa' },
  { value: 'laRioja', label: 'La Rioja' },
  { value: 'mendoza', label: 'Mendoza' },
  { value: 'misiones', label: 'Misiones' },
  { value: 'neuquen', label: 'Neuquén' },
  { value: 'rioNegro', label: 'Río Negro' },
  { value: 'salta', label: 'Salta' },
  { value: 'sanJuan', label: 'San Juan' },
  { value: 'sanLuis', label: 'San Luis' },
  { value: 'santaCruz', label: 'Santa Cruz' },
  { value: 'santaFe', label: 'Santa Fe' },
  { value: 'santiagoDelEstero', label: 'Santiago del Estero' },
  { value: 'tierraDelFuego', label: 'Tierra del Fuego' },
  { value: 'tucuman', label: 'Tucumán' },
];

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Account = ({ windowSize, incrementProgressLoad }) => {

  const { signedIn, setSignedIn } = useContext(UserContext);

  const { setWhiteNav } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  const phoneScreen = windowSize.width < 768 && windowSize.orientation === 'portrait';

  const [activeTab, setActiveTab] = useState(1);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [buttonStatus, setButtonStatus] = useState('CREAR CUENTA');

  const [signEmail, setSignEmail] = useState('');
  const [signPassword, setSignPassword] = useState('');
  const [signButtonStatus, setSignButtonStatus] = useState('INICIAR SESIÓN');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [floor, setFloor] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [local, setLocal] = useState('');
  const [province, setProvince] = useState('');
  const [saveButtonStatus, setSaveButtonStatus] = useState('GUARDAR');

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  useEffect(() => {
    setWhiteNav(false);
    incrementProgressLoad(100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signedIn && firebase.auth().currentUser) {
      const firestore = firebase.firestore();

      const docRef = firestore.collection("users").doc(firebase.auth().currentUser.uid);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setName(doc.data().name || '');
            setPhone(doc.data().phone || '');
            setStreet(doc.data().street || '');
            setStreetNumber(doc.data().streetNumber || '');
            setFloor(doc.data().floor || '');
            setZipCode(doc.data().zipCode || '');
            setLocal(doc.data().local || '');
            setProvince(doc.data().province || '');
          }
        })
        .catch(error => {
          errorLog("Getting user data", { userID: firebase.auth().currentUser.uid }, "get", "Account", error.message);
          showNotification("Error", "Ha ocurrido un error cargando los datos. Por favor, vuelva a intentar.", "error");
        });

      firestore.collection("orders").where('client.userID', '==', firebase.auth().currentUser.uid).orderBy('date', 'desc')
        .get()
        .then(querySnapshot => {
          setOrders(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
          setLoadedOrders(true);
        })
        .catch(error => {
          console.log(error);
          setLoadedOrders(true);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const handleSignUp = () => {
    setButtonStatus("CREANDO...");

    firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
      .then(credential => {
        setButtonStatus("CREAR CUENTA");
        setSignedIn(true);
      })
      .catch(error =>  {
        //console.log(error);
        var errorCode = error.code;
        setButtonStatus("CREAR CUENTA");
        errorLog("On account sign up", "none", "signUp", "Account", error.message);
        

        switch(errorCode) {
          case "auth/email-already-in-use":
            showNotification("Email en uso", "Ya existe una cuenta con este email. Por favor, intente nuevamente con otro.", "error");
            break;
          case "auth/invalid-email":
            showNotification("Email Inválido", "La dirección de email es inválida. Por favor, intente nuevamente.", "error");
            break;
          case "auth/weak-password":
            showNotification("Contraseña demasiado débil", "La contraseña es demasiado débil. Por favor, intente nuevamente con otra.", "error");
            break;
          default:
            showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
        }
      });
  }

  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        setSignedIn(false);
      })
      .catch(() => {
        showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
      })
  }

  const handleSignIn = () => {
    setSignButtonStatus('INICIANDO SESIÓN...');

    firebase.auth().signInWithEmailAndPassword(signEmail.toLowerCase(), signPassword)
      .then(credential => {
        setSignButtonStatus("INICIAR SESIÓN");
        setSignedIn(true);
      })
      .catch(error => {
        var errorCode = error.code;
        errorLog("On account sign in", "none", "signIn", "Account", error.message);
        //console.log(error);
        
        switch(errorCode) {
          case "auth/invalid-email":
            showNotification("Email Inválido", "La dirección de email es inválida. Por favor, intente nuevamente.", "error");
            break;
          case "auth/user-disabled":
            showNotification("Usuario Inhabilitado", "El usuario ha sido inhabilitado. Contáctanos para más información.", "error");
            break;
          case "auth/user-not-found":
            showNotification("Usuario/Contraseña Incorrectos", "El usuario o la contraseña son incorrectos. Por favor, vuelva a intentar.", "error");
            break;
          case "auth/wrong-password":
            showNotification("Usuario/Contraseña Incorrectos", "El usuario o la contraseña son incorrectos. Por favor, vuelva a intentar.", "error");
            break;
          default:
            showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
          }
      });
  }

  const saveInfo = () => {
    setSaveButtonStatus("GUARDANDO...");
    const firestore = firebase.firestore();

    const docRef = firestore.collection("users").doc(firebase.auth().currentUser.uid);

    docRef
      .update({
        name: name,
        phone: phone,
        street: street,
        streetNumber: streetNumber,
        floor: floor,
        zipCode: zipCode,
        local: local,
        province: province
      })
      .then(() => {
        showNotification("Información Actualizada", "Tu información ha sido actualizada con éxito.", "success");
        setSaveButtonStatus("GUARDAR");
      })
      .catch(error => {
        //console.log(error);
        errorLog("Saving user info", "none", "update", "Account", error.message);

        setSaveButtonStatus("GUARDAR");
        showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
      });
  }

  const inputPhone = (phone) => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const formatDate = (mDate) => {
    const date = new Date(mDate);

    return date.toLocaleDateString('es-AR');
  }

  const openTicket = url => {
    window.open(url, '_blank');
  }

  const formatSubtotal = items => {
    const formatPrice = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " ARS";
      } catch (e) {
        console.log(e)
      }
    };

    return formatPrice(items.reduce((accum, curr) => accum + parseInt(curr.price,10) * curr.quantity, 0));
  }

  const [showMain, setShowMain] = useState(true);
  const mainContainerProps = useSpring({ opacity: showMain ? 1 : 0 });

  const toggleTab = tabIndex => {
    setShowMain(false);

    sleep(400).then(() => {
      setActiveTab(tabIndex);
      setShowMain(true);
    });
  }

  const resetPassword = () => {
    if (signEmail === '') {
      showNotification("Error", "Debes ingresar el email de la cuenta que quieres recuperar.", "error");
    } else {
      firebase.auth().sendPasswordResetEmail(signEmail).then(() => {
        showNotification("Correo Enviado!", "Te hemos enviado un correo para que puedas cambiar tu contraseña. Puede tardar algunos minutos en llegar.", "success");
      }).catch((error) => {
        var errorCode = error.code;
        //console.log(error);
        errorLog("Resetting password", { email: signEmail }, "passwordReset", "Account", error.message);
        
        switch(errorCode) {
          case "auth/invalid-email":
            showNotification("Email Inválido", "La dirección de email es inválida. Por favor, intente nuevamente.", "error");
            break;
          case "auth/user-not-found":
            showNotification("Usuario/Contraseña Incorrectos", "El usuario o la contraseña son incorrectos. Por favor, vuelva a intentar.", "error");
            break;
          default:
            showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
          }
      });
    }
  }

  return (
    <div className="contactContainer" style={{ height: windowSize.height }}>
      { signedIn
      ? <div className="accountContainer">
          <div className="accountSidebar">
            <div className="accountSidebarLink" onClick={() => toggleTab(1)}>
              {phoneScreen ? <FileText className="mobileSidebarIcon" /> : "INFORMACIÓN"}
              <div className="accountSidebarLinkDot" />
            </div>
            <div className="accountSidebarLink" onClick={() => toggleTab(2)}>
              {phoneScreen ? <List className="mobileSidebarIcon" /> : "MIS ÓRDENES"}
              <div className="accountSidebarLinkDot" />
            </div>
            <div className="accountSidebarLink" onClick={() => handleSignOut()}>
              {phoneScreen ? <LogOut className="mobileSidebarIcon" /> : "CERRAR SESIÓN"}
              <div className="accountSidebarLinkDot" />
            </div>
          </div>
          <animated.div style={mainContainerProps} className="accountMainContainer">
            { activeTab === 1
            ? <div className="accountMainInfoContainer">
                <div className="accountMainInfoColumn">
                  <FormTextInput placeholder="Nombre" value={name} onChange={setName} color='#333' />
                  <FormTextInput placeholder="Teléfono" value={phone} onChange={inputPhone} color='#333' />
                  <FormTextInput placeholder="Calle" value={street} onChange={setStreet} color='#333' />
                  <FormTextInput placeholder="Altura" value={streetNumber} onChange={setStreetNumber} color='#333' />
                </div>
                <div className="accountMainInfoColumn">
                  <FormTextInput placeholder="Piso/Departamento" value={floor} onChange={setFloor} color='#333' />
                  <FormTextInput placeholder="Código Postal" value={zipCode} onChange={setZipCode} color='#333' />
                  <FormTextInput placeholder="Localidad" value={local} onChange={setLocal} color='#333' />
                  <p className="formInputSimpleLabel" style={{ color: '#333' }}>Provincia</p>
                  <select placeholder="Provincia" value={province} onChange={(e) => setProvince(e.target.value)} className="singleProductSelect">
                    <option key="" value="" disabled className="singleProductSelectOption">Seleccione una provincia</option>
                    {provinces.map(province => 
                      <option key={province.value} value={province.value} className="singleProductSelectOption">{province.label}</option>
                    )}
                  </select>
                  <button onClick={() => saveInfo()} className="signInButton">{saveButtonStatus}</button>
                </div>
              </div>
            : activeTab === 2
              ? <div className="accountOrdersContainer">
                  <div className="collectionsTitleLine">
                    <p className="collectionsTitle">Mis Órdenes</p>
                  </div>
                  <div className="collectionsListContainer">
                    <div className="collectionHeaderLine">
                      <p className="orderDateHeaderColumn">Fecha</p>
                      <p className="orderDateHeaderColumn orderIdHeaderColumn">ID</p>
                      <p className="orderDateHeaderColumn orderPriceHeaderColumn">Precio</p>
                      <p className="orderDateHeaderColumn orderStatusHeaderColumn">Estado</p>
                      <p className="orderDateHeaderColumn orderPrintHeaderColumn"></p>
                    </div>
                    { loadedOrders && orders.length > 0 ? orders.map(order =>
                      <div key={order.id} className="orderLine">
                        <div className="orderDateLineColumn">{formatDate(order.date)}</div>
                        <div className="orderDateLineColumn orderIdHeaderColumn" >{order.id}</div>
                        <div className="orderDateLineColumn orderPriceHeaderColumn">{formatSubtotal(order.items)}</div>
                        <div className="orderDateLineColumn orderStatusHeaderColumn">
                          <div className="orderStatusContainer" style={{ backgroundColor: order.status === 'approved' ? "#4caf50" : order.status === 'rejected' ? "#f44336" : "#2196f3" }}>
                            {order.status === 'approved' ? "APROBADA" : order.status === 'rejected' ? "RECHAZADA" : "PENDIENTE"}
                          </div>
                        </div>
                        <div className="orderDateLineColumn orderPrintHeaderColumn" style={{ justifyContent: 'center' }}>
                          <Printer className="collectionLineIcon" onClick={() => openTicket(order.confirmation.url)} />
                        </div>
                      </div>
                    ) : loadedOrders ? <div className="loadingCollections">No hay órdenes.</div> : <div className="loadingCollections">Cargando órdenes...</div>}
                  </div>
                </div>
              : null
            }
          </animated.div>
        </div>
      : <div className="authContainer">
          <div className="signInContainer" style={{ borderRight: '1px solid rgba(0,0,0,0.03)' }}>
            <p className="signInTitle">Inicia Sesión</p>
            <div className="signInForm">
              <FormTextInput placeholder="Email" value={signEmail} onChange={setSignEmail} color='#333' />
              <FormTextInput password placeholder="Contraseña" value={signPassword} onChange={setSignPassword} color='#333' />
              <button disabled={signEmail === '' || signPassword === ''} onClick={() => handleSignIn()} className="signInButton">{signButtonStatus}</button>
              <p className="forgotPasswordButton" onClick={() => resetPassword()}>¿Olvidaste tu contraseña?</p>
            </div>
          </div>
          <div className="signInContainer">
            <p className="signInTitle" style={{ textAlign: 'right' }}>Creá tu cuenta ahora</p>
            <div className="signInForm">
              <FormTextInput placeholder="Email" value={email} onChange={setEmail} color='#333' />
              <FormTextInput password placeholder="Contraseña" value={password} onChange={setPassword} color='#333' />
              <FormTextInput password placeholder="Confirmar Contraseña" value={confirm} onChange={setConfirm} color='#333' />
              <button disabled={email === '' || password === '' || password !== confirm} onClick={() => handleSignUp()} className="signInButton">{buttonStatus}</button>
              <p className="accountPolicyText">
                Al crear la cuenta, te declaras en concordancia con <a className="accountPolicyLink" href="https://yunta-s3-bucket.s3.us-east-2.amazonaws.com/Poli%CC%81tica+de+Privacidad+-+Tienda+VDLC.pdf" target="_blank" rel="noopener noreferrer">nuestra política de privacidad.</a>
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Account;