export const deliveryPrices = {
    standard: {
        regional: {
            upTo1: 9000,
            upTo5: 10500,
            upTo10: 14200,
            upTo15: 17600,
            upTo20: 20600,
            upTo25: 24900,
            rest: 34860,
        },
        nonRegional: {
            upTo1: 12100,
            upTo5: 14600,
            upTo10: 20700,
            upTo15: 25900,
            upTo20: 30200,
            upTo25: 37000,
            rest: 51800,
        }
    },
    priority: {
        regional: {
            upTo1: 14200,
            upTo5: 16300,
            upTo10: 21800,
            upTo15: 27900,
            upTo20: 31500,
            upTo25: 37300,
            rest: 52220,
        },
        nonRegional: {
            upTo1: 17700,
            upTo5: 24300,
            upTo10: 44700,
            upTo15: 60200,
            upTo20: 74700,
            upTo25: 92800,
            rest: 129920,
        }
    }
}