import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useHistory } from "react-router-dom";
import { easeExpInOut } from 'd3-ease';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { ArrowRight } from 'react-feather';

import { HomePlusButton } from '../Components/HomePlusButton';

import { useBubblesTransition } from '../Hooks/useBubblesTransition';

import { errorLog } from '../Helpers/errorLog';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';

import './Home.css';

const Home = ({ windowSize, incrementProgressLoad, setNavBg, setWhatsappBtn }) => {

  const homeRef = useRef();

  const history = useHistory();

  const { showNotification } = useContext(NotificationContext);

  const { setWhiteNav, webP } = useContext(TransitionContext);

  const { transitionTo } = useBubblesTransition();

  useEffect(() => {
    setWhiteNav(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const onScroll = (e) => {
    if (e.target.scrollingElement.scrollTop > 30) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }

    if (e.target.scrollingElement.scrollTop > windowSize.height * 1.5) {
      setWhatsappBtn(false);
    } else {
      setWhatsappBtn(true);
    }
  }

  const [newsletterEmail, setNewsletterEmail] = useState('');

  const [exploreMore, setExploreMore] = useState(false);

  const [collectionTransition, setCollectionTransition] = useState(false);

  const collectionTransitionProps = useSpring({ 
    from: { width: 'calc(60vw)', bottom: 'calc(-105vh)' },
    to: { width: collectionTransition ? 'calc(100vw)' : 'calc(60vw)', bottom: collectionTransition ? 'calc(0vh)' : exploreMore ? 'calc(-95vh)' : 'calc(-105vh)' },
    config: { duration: 1200, easing: easeExpInOut },
    onRest: () => triggerTransition()
  });

  const triggerTransition = () => {
    if (collectionTransition) {
      window.scrollTo({ top: 0 });
      setWhiteNav(true);
      history.push('/coleccion');
    }
  }

  const desktopScreen = windowSize.width > 1024 || windowSize.orientation === "landscape";
  const phoneScreen = windowSize.width < 768;

  const [ctaHovered, setCtaHovered] = useState(false);
  const ctaProps = useSpring({ width: ctaHovered ? (desktopScreen ? 'calc(2vw)' : phoneScreen ? 'calc(7vw)' : 'calc(5vw)') : (desktopScreen ? 'calc(4vw)' : phoneScreen ? 'calc(10vw)' : 'calc(8vw)'), height: ctaHovered ? (desktopScreen ? 'calc(2vw)' : phoneScreen ? 'calc(7vw)' : 'calc(5vw)') : (desktopScreen ? 'calc(4vw)' : phoneScreen ? 'calc(10vw)' : 'calc(8vw)'), right: desktopScreen ? 'calc(-2.5vw)' : 'calc(-4.5vw)', borderColor: ctaHovered ? '#e1e1e1' : 'rgba(225,225,225,0.3)' });

  const [newsletterHovered, setNewsletterHovered] = useState(false);
  const newsletterProps = useSpring({ transform: newsletterHovered && newsletterEmail !== '' ? 'skew(-5deg)' : 'skew(0deg)', filter: newsletterHovered && newsletterEmail !== '' ? 'brightness(0.8)' : 'brightness(1)' });

  // const handleNewsletterFocus = () => {
  //   document.getElementById("newsletterInput").focus();

  //   var ua = navigator.userAgent.toLowerCase();
  //   var isAndroid = ua.indexOf("android") > -1;

  //   if (isAndroid) {
  //     document.getElementById("homeScrollArea").scrollTop = document.getElementById("homeScrollArea").scrollTop * 0.725;
  //   }
  // }

  const handleNewsletterBlur = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;

    if (isAndroid) {
      document.getElementById("homeScrollArea").scrollTop = document.getElementById("homeScrollArea").scrollTop / 0.725;
    }
  }

  const [newsletterButtonStatus, setNewsletterButtonStatus] = useState('Enviar');

  const handleSendNewsletter = () => {
    setNewsletterButtonStatus('Enviando...');

    const firestore = firebase.firestore();

    firestore.collection('mailing').add({
      email: newsletterEmail
    })
    .then(() => {
      showNotification("Email Guardado", "Hemos guardado tu dirección de correo para enviarte los mejores beneficios.", "success");
      setNewsletterEmail('');
      setNewsletterButtonStatus('Enviar');
    })
    .catch(error => {
      errorLog("Saving newsletter mail", { email: newsletterEmail }, "add", "Home", error.message);
      showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
    })
  }

  const mobileScreen = windowSize.width <= 1024;

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      { mobileScreen
      ? <animated.img src={require('../assets/images/renaissanceMobile.jpg')} alt="Escultura del renacimiento" className="homeTransitionImg" style={collectionTransitionProps} />
      : webP ? <animated.img src={require(`../assets/images/${webP === 'webp' ? 'renaissance.webp' : 'renaissance.jpg'}`)} alt="Escultura del renacimiento" className="homeTransitionImg" style={collectionTransitionProps} /> : null
      }
      <div className="homeOuter" style={{ height: windowSize.height }}>
        <animated.div className="homeContainer" ref={homeRef}>
          <div className="homeHeader">
            <div className="homeHeaderTitleContainer">
              <p className="homeHeaderTitleLeft">#LO SOÑASTE,</p>
              <p className="homeHeaderTitleRight">PODÉS TENERLO</p>
            </div>
            <div className="homeHeaderImgWrapper">
              { mobileScreen
              ? <img src={require('../assets/images/altHome_header1Mobile.jpg')} alt="Encabezado Coleccion VDLC" className="homeHeaderImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} />
              : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'altHome_header1.webp' : 'altHome_header1.jpg'}`)} alt="Encabezado Coleccion VDLC" className="homeHeaderImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} /> : null
              }
            </div>
            <div className="collectionCTAContainer" onClick={() => transitionTo('/coleccion')} onMouseEnter={() => setCtaHovered(true)}  onMouseLeave={() => setCtaHovered(false)}>
              <p className="ctaText">Explora nuestra última colección</p>
              <animated.div className="ctaArrowContainer" style={ctaProps}>
                <ArrowRight className="homeArrowGlyph" />
              </animated.div>
            </div>
          </div>
          <div className="homeSubheader">
            <div className="homeSubheaderImgWrapperLeft">
              { mobileScreen
              ? <img src={require('../assets/images/altHome_2Mobile.jpg')} alt="Coleccion VDLC" className="homeSubheaderImgLeft" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} />
              : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'altHome_2.webp' : 'altHome_2.jpg'}`)} alt="Coleccion VDLC" className="homeSubheaderImgLeft" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} /> : null
              }
            </div>
            <div className="homeSubheaderImgWrapperRight">
              { mobileScreen
              ? <img src={require('../assets/images/altHome_3Mobile.jpg')} alt="Coleccion VDLC" className="homeSubheaderImgRight" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} />
              : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'altHome_3.webp' : 'altHome_3.jpg'}`)} alt="Coleccion VDLC" className="homeSubheaderImgRight" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} /> : null
              }
            </div>
            <div className="subheaderOverlay">
            <div className="subheaderPlusOverlay" onClick={() => transitionTo('/coleccion')} />
          </div>
            <HomePlusButton />
          </div>
          <div className="homePhraseContainer">
            <p className="homePhrase">Me imagino en cada mujer a una estrella, a una reina, a una amante y en base a todo lo que imagino de ella la visto. Imaginar es mi vida y creo que es la esencia de cada mujer.</p>
          </div>
          <div className="newsletterContainer">
            <div className="newsletterImgWrapper">
              { mobileScreen
              ? <img src={require('../assets/images/altNewsletter_mobile.jpg')} alt="Coleccion VDLC" className="newsletterImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} />
              : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'altNewsletter.webp' : 'altNewsletter.jpg'}`)} alt="Coleccion VDLC" className="newsletterImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} /> : null
              }
            </div>
            <div className="newsletterInfoContainer">
              <p className="newsletterInfoTitle">SUSCRIBITE A NUESTRO NEWSLETTER</p>
              <p className="newsletterInfoSub">Enterate de todas las novedades y obtené increíbles descuentos y beneficios.</p>
              <input type="text" id="newsletterInput" className="newsletterEmailInput" value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)} placeholder="Tu dirección de email" onBlur={() => handleNewsletterBlur()} />
              <animated.button className="newsletterSubmitButton" disabled={newsletterEmail === ''} style={newsletterProps} onClick={handleSendNewsletter} onMouseEnter={() => setNewsletterHovered(true)}  onMouseLeave={() => setNewsletterHovered(false)}>{newsletterButtonStatus}</animated.button>
            </div>
          </div>
          <div className="homeDecorationImgWrapper">
              { mobileScreen
              ? <img src={require('../assets/images/homeDecorationMobile.jpg')} alt="Escultura Bernini Renacimiento" className="homeDecorationImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} />
              : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'homeDecoration.webp' : 'homeDecoration.jpg'}`)} alt="Escultura Bernini Renacimiento" className="homeDecorationImg" onLoad={() => incrementProgressLoad(20)} onError={() => incrementProgressLoad(20)} /> : null
              }
          </div>
          <div className="homeNextContainer">
            <div className="homeNextTitleContainer" style={{ cursor: "pointer" }} onClick={desktopScreen ? () => { setNavBg(false); setCollectionTransition(true); } : () => transitionTo('/coleccion')} onMouseEnter={desktopScreen ? () => setExploreMore(true) : null} onMouseLeave={desktopScreen ? () => setExploreMore(false) : null}>
              <p className="homeNextTitle">DESCUBRÍ LO</p>
              <p className="homeNextTitle">NUEVO</p>
            </div>
            <animated.div className="homeNextLine" style={{ width: exploreMore ? 'calc(0vw)' : 'calc(100vw)' }} />
            <p className="footerInfo">{`${(new Date()).getFullYear()} © Vero De La Canal`}</p>
            <a href="https://www.estudioanima.com" style={{ cursor: 'pointer' }} className="animaLogo" target="_blank" rel="noopener noreferrer">
                <img src={require('../assets/images/animaLogo.png')} alt="Logo ANIMA Estudio Creativo" style={{ height: '100%', width: 'auto' }} />
            </a>
            <a href="https://www.lucilaelsegood.com" style={{ cursor: 'pointer' }} className="leLogo" target="_blank" rel="noopener noreferrer">
                <img src={require('../assets/images/leLogo.png')} alt="Logo Lucila Elsegood" style={{ height: '100%', width: 'auto' }} />
            </a>
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default Home;