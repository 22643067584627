import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { easeExpInOut } from 'd3-ease';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';

import { errorLog } from '../Helpers/errorLog';

import './Collection.css';
import './NewIn.css';

const categories = ['todos', 'vestidos y monos', 'camisas y blusas', 'remeras', 'corsets', 'pantalones', 'faldas', 'blazers', 'chalecos', 'tapados y camperas', 'buzos y sweaters', 'tapabocas', 'bags', 'accesorios'];

const truncate = require('lodash.truncate');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Collection = ({ windowSize, setNavBg, setWhatsappBtn, incrementProgressLoad, setShowProductOverlay, setOverlayProduct }) => {

  const collectionRef = useRef();

  const { showNotification } = useContext(NotificationContext);

  const { setWhiteNav, webP } = useContext(TransitionContext);

  useEffect(() => {
    setWhiteNav(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const [productGroups, setProductGroups] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState('todos');

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  let loadingMoreProducts = useRef();
  if (!loadingMoreProducts.current) loadingMoreProducts.current = null;

  useEffect(() => {
    const firestore = firebase.firestore();

    let query = firestore.collection("products");

    if (selectedCategory !== 'todos') {
      query = query.where("category", "==", selectedCategory);
    };

    query
      .where('active', '==', true)
      .where('history', '==', false)
      .orderBy('random', 'desc')
      .limit(6)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];  

          const mProducts = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setProductGroups([mProducts]);

          if (querySnapshot.docs.length < 6) {
            loadingMoreProducts.current = true;
          } else {
            loadingMoreProducts.current = false;
          }

        } else {
          setProductGroups([]);
        }

        setLoadedProducts(true);
      })
      .catch(error => {
        console.log(error);
        errorLog("Initial product load", "none", "get", "Collection", error.message);
        setLoadedProducts(true);
        showNotification('Error', 'Ha ocurrido un error cargando los datos de los productos. Por favor, vuelva a intentar.', 'error');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const loadMoreProducts = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection("products");

      if (selectedCategory !== 'todos') {
        query = query.where("category", "==", selectedCategory);
      };

      query
        .where('active', '==', true)
        .where('history', '==', false)
        .orderBy('random', 'desc')
        .startAfter(nextPointer.current)
        .limit(6)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

            const mProducts = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setProductGroups(pg => pg.concat([mProducts]));

            if (querySnapshot.docs.length < 6) {
              loadingMoreProducts.current = true;
            } else {
              loadingMoreProducts.current = false;
            }
          }
        })
        .catch((error) => {
          errorLog("Loading more products", "none", "get", "Collection", error.message);
          showNotification('Error', 'Ha ocurrido un error cargando los datos de los productos. Por favor, vuelva a intentar.', 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatPrice = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " ARS";
    } catch (e) {
      console.log(e)
    }
  };

  const onScroll = (e) => {
    if (e.target.scrollingElement.scrollTop > windowSize.height - 100) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }

    if (e.target.scrollingElement.scrollTop > windowSize.height * 1.5) {
      setWhatsappBtn(false);
    } else {
      setWhatsappBtn(true);
    }

    if (e.target.scrollingElement.scrollTop > e.target.scrollingElement.scrollHeight / 2 && nextPointer.current && !loadingMoreProducts.current) {
      loadingMoreProducts.current = true;
      loadMoreProducts();
    }
  }

  const desktopScreen = windowSize.width > 1024 || windowSize.orientation === "landscape";
  const phoneScreen = windowSize.width < 768;

  const titleProps = useSpring({ from: { bottom: 'calc(-15vh)' }, to: { bottom: 'calc(0vh)' }, config: { duration: 1500, easing: easeExpInOut } });

  const hoverCategories = status => {
    const containerSize = desktopScreen ? `100vh` : `90vh`;

    if (status) {
      document.getElementById("innerCategories").style.height = containerSize;
      document.getElementById("innerCategories").style.opacity = 1;
  
      document.getElementById("overlayCategories").style.height = containerSize;
    } else {
      document.getElementById("innerCategories").style.height = `0vh`;
      document.getElementById("innerCategories").style.opacity = 0.1;
  
      document.getElementById("overlayCategories").style.height = `0vh`;
    }
  }

  const hoverCategory = (index, status) => {
    if (status && document.getElementById(`innerCategory_${index}`)) {
      document.getElementById(`innerCategory_${index}`).style.width = `100%`;
    } else if (document.getElementById(`innerCategory_${index}`)) {
      document.getElementById(`innerCategory_${index}`).style.width = `0%`;
    }
  }

  const hoverProduct = (groupIndex, index, status) => {
    if (status && document.getElementById(`innerProductImg_${groupIndex}_${index}`)) {
      document.getElementById(`innerProductImg_${groupIndex}_${index}`).style.transform = `scale(1.15)`;
    } else if (document.getElementById(`innerProductImg_${groupIndex}_${index}`)) {
      document.getElementById(`innerProductImg_${groupIndex}_${index}`).style.transform = `scale(1)`;
    }
  }

  const handleSelectCategory = (category, e = null) => {
    if (e) e.stopPropagation();

    setSelectedCategory(category);

    if (!desktopScreen) {
      hoverCategories(false);
    }
  }

  const openProduct = (product) => {
    setOverlayProduct(product);

    sleep(100).then(() => {
      setShowProductOverlay(true);
    })
  }

  const mobileScreen = windowSize.width <= 1024;

  const getDisplayImage = product => {
    const allImages = product ? product.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images.filter(image => !image.video))}), { images: [] }) : [];

    if (allImages.images.length > 0) {
      if (mobileScreen && allImages.images[0].mobile) {
        return allImages.images[0].mobile.url;
      } else if (webP === 'webp' && allImages.images[0].webp) {
        return allImages.images[0].webp.url;
      } else if (allImages.images[0].jpg) {
        return allImages.images[0].jpg.url;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const hasNoStock = product => {
    if (!product) return true;
    
    return product.options.every(option => option.sizes.every(size => parseInt(size.stock, 10) === 0));
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="homeOuter">
        <animated.div className="collectionContainer" ref={collectionRef}>
          <div className="collectionHeader" style={{ height: windowSize.height }}>
            <div className="collectionTitleContainer">
              <animated.p style={titleProps} className="collectionTitle">New In</animated.p>
            </div>
            { mobileScreen
            ? <animated.img src={require('../assets/images/renaissanceMobile.jpg')} onLoad={() => incrementProgressLoad(100)} onError={() => incrementProgressLoad(100)} alt="Fresco del Renacimiento" className="collectionTransitionImg" />
            : webP ? <animated.img src={require(`../assets/images/${webP === 'webp' ? 'renaissance.webp' : 'renaissance.jpg'}`)} onLoad={() => incrementProgressLoad(100)} onError={() => incrementProgressLoad(100)} alt="Fresco del Renacimiento" className="collectionTransitionImg" /> : null
            }
          </div>
          <div className="categoriesSelectorOuterContainer">
            <div className="categoriesSelectorText">
              categorías
              <animated.div id="innerCategories" className="categoriesSelectorInner">
                <div className="categoriesSelectorInnerContainer">
                  { categories.map((category, index) => 
                    <div key={category} style={{ color: category === selectedCategory ? '#a6513f' : '#333' }} className="categoryText" onClick={(e) => handleSelectCategory(category, e)} onMouseEnter={() => hoverCategory(index, true)} onMouseLeave={() => hoverCategory(index, false)}>
                      {category}
                      <div id={`innerCategory_${index}`} className="categoryTextUnderline" />
                    </div>
                  )}
                </div>
              </animated.div>  
            </div>
          </div>
          <div className="productsContainer">
            { loadedProducts && productGroups.length > 0 
            ? productGroups.map((group, index) => 
                <div key={index} className="productGroupContainer">
                  <div className="firstMainContainer" onClick={() => openProduct(group[0])} onMouseEnter={() => hoverProduct(index, 0, true)} onMouseLeave={() => hoverProduct(index, 0, false)}>
                    <div className="firstMainImgWrapper">
                      {!group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                      ? <img id={`innerProductImg_${index}_0`} src={getDisplayImage(group[0]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="firstMainImg" />
                      : <div key={group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                          <video autoPlay muted playsInline loop id={group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                            <source src={`${group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                          </video>
                        </div>
                      }
                      { hasNoStock(group[0])
                        ? <div className="preOrderBanner">AGOTADO</div> 
                        : group[0].category === 'pre order'
                          ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                          : null
                      }
                    </div>
                    <div className="firstMainInfo">
                      <p className="firstMainInfoTitle">{group[0].name}</p>
                      <p className="firstMainInfoDesc">{truncate(group[0].description, { 'length': phoneScreen ? 120 : 240 })}</p>
                      <p className="firstMainInfoPrice"><span style={{ textDecoration: group[0].options[0].offer ? 'line-through' : 'none', opacity: group[0].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[0].options[0].price)}</span><span style={{ display: group[0].options[0].offer ? 'flex' : 'none', marginTop: 6 }}>{formatPrice(group[0].options[0].priceOffer)}</span></p>
                    </div>
                  </div>
                  { group[1]
                  ? <div className="doubleContainer">
                      <div className="doubleContainerInner" onClick={() => openProduct(group[1])} onMouseEnter={() => hoverProduct(index, 1, true)} onMouseLeave={() => hoverProduct(index, 1, false)}>
                        <div className="doubleContainerImgWrapper">
                          {!group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                          ? <img id={`innerProductImg_${index}_1`} src={getDisplayImage(group[1]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                          : <div key={group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                              <video autoPlay muted playsInline loop id={group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                <source src={`${group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                              </video>
                            </div>
                          }
                          { hasNoStock(group[1])
                            ? <div className="preOrderBanner">AGOTADO</div> 
                            : group[1].category === 'pre order'
                              ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                              : null
                          }                        </div>
                        <p className="doubleContainerName">{group[1].name}</p>
                        <p className="doubleContainerPrice"><span style={{ textDecoration: group[1].options[0].offer ? 'line-through' : 'none', opacity: group[1].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[1].options[0].price)}</span><span style={{ display: group[1].options[0].offer ? 'flex' : 'none', marginLeft: 6 }}>{formatPrice(group[1].options[0].priceOffer)}</span></p>
                      </div>
                      { group[2]
                      ? <div className="doubleContainerInner" onClick={() => openProduct(group[2])} onMouseEnter={() => hoverProduct(index, 2, true)} onMouseLeave={() => hoverProduct(index, 2, false)}>
                          <div className="doubleContainerImgWrapper">
                            {!group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                            ? <img id={`innerProductImg_${index}_2`} src={getDisplayImage(group[2]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                            : <div key={group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                                <video autoPlay muted playsInline loop id={group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                  <source src={`${group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                                </video>
                              </div>
                            }
                            { hasNoStock(group[2])
                              ? <div className="preOrderBanner">AGOTADO</div> 
                              : group[2].category === 'pre order'
                                ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                                : null
                            }
                          </div>
                          <p className="doubleContainerName">{group[2].name}</p>
                          <p className="doubleContainerPrice"><span style={{ textDecoration: group[2].options[0].offer ? 'line-through' : 'none', opacity: group[2].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[2].options[0].price)}</span><span style={{ display: group[2].options[0].offer ? 'flex' : 'none', marginLeft: 6 }}>{formatPrice(group[2].options[0].priceOffer)}</span></p>
                        </div>
                      : null
                      }
                    </div>
                  : null
                  }
                  { group[3]
                  ? <div className="firstMainContainer" onClick={() => openProduct(group[3])} onMouseEnter={() => hoverProduct(index, 3, true)} onMouseLeave={() => hoverProduct(index, 3, false)}>
                      <div className="secondMainInfo">
                        <p className="firstMainInfoTitle" style={{ alignSelf: 'flex-end', textAlign: 'right' }}>{group[3].name}</p>
                        <p className="firstMainInfoDesc" style={{ textAlign: 'right' }}>{truncate(group[3].description, { 'length': phoneScreen ? 120 : 240 })}</p>
                        <p className="firstMainInfoPrice"><span style={{ textDecoration: group[3].options[0].offer ? 'line-through' : 'none', opacity: group[3].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[3].options[0].price)}</span><span style={{ display: group[3].options[0].offer ? 'flex' : 'none', marginTop: 6 }}>{formatPrice(group[3].options[0].priceOffer)}</span></p>
                      </div>
                      <div className="firstMainImgWrapper">
                        {!group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                        ? <img id={`innerProductImg_${index}_3`} src={getDisplayImage(group[3]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="firstMainImg" />
                        : <div key={group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                            <video autoPlay muted playsInline loop id={group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                              <source src={`${group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                            </video>
                          </div>
                        }
                        { hasNoStock(group[3])
                          ? <div className="preOrderBanner">AGOTADO</div> 
                          : group[3].category === 'pre order'
                            ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                            : null
                        }
                      </div>
                    </div>
                  : null
                  }
                  { group[4]
                  ? <div className="doubleContainer">
                      <div className="doubleContainerInner" onClick={() => openProduct(group[4])} onMouseEnter={() => hoverProduct(index, 4, true)} onMouseLeave={() => hoverProduct(index, 4, false)}>
                        <div className="doubleContainerImgWrapper">
                          {!group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                          ? <img id={`innerProductImg_${index}_4`} src={getDisplayImage(group[4]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                          : <div key={group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                              <video autoPlay muted playsInline loop id={group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                <source src={`${group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                              </video>
                            </div>
                          }
                          { hasNoStock(group[4])
                            ? <div className="preOrderBanner">AGOTADO</div> 
                            : group[4].category === 'pre order'
                              ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                              : null
                          }
                        </div>
                        <p className="doubleContainerName">{group[4].name}</p>
                        <p className="doubleContainerPrice"><span style={{ textDecoration: group[4].options[0].offer ? 'line-through' : 'none', opacity: group[4].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[4].options[0].price)}</span><span style={{ display: group[4].options[0].offer ? 'flex' : 'none', marginLeft: 6 }}>{formatPrice(group[4].options[0].priceOffer)}</span></p>
                      </div>
                      { group[5]
                      ? <div className="doubleContainerInner" onClick={() => openProduct(group[5])} onMouseEnter={() => hoverProduct(index, 5, true)} onMouseLeave={() => hoverProduct(index, 5, false)}>
                          <div className="doubleContainerImgWrapper">
                            {!group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                            ? <img id={`innerProductImg_${index}_5`} src={getDisplayImage(group[5]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                            : <div key={group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                                <video autoPlay muted playsInline loop id={group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                  <source src={`${group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                                </video>
                              </div>
                            }
                            { hasNoStock(group[5])
                              ? <div className="preOrderBanner">AGOTADO</div> 
                              : group[5].category === 'pre order'
                                ? <div className="preOrderBanner" style={{ backgroundColor: '#a6513f', color: '#fff' }}>PRE ORDER</div>
                                : null
                            }
                          </div>
                          <p className="doubleContainerName">{group[5].name}</p>
                          <p className="doubleContainerPrice"><span style={{ textDecoration: group[5].options[0].offer ? 'line-through' : 'none', opacity: group[5].options[0].offer ? 0.3 : 1 }}>{formatPrice(group[5].options[0].price)}</span><span style={{ display: group[5].options[0].offer ? 'flex' : 'none', marginLeft: 6 }}>{formatPrice(group[5].options[0].priceOffer)}</span></p>
                        </div>
                      : null
                      }
                    </div>
                  : null
                  }
                </div>
              )
            : <div className="newInLoadingContainer">
                { loadedProducts ? "No hay productos disponibles con este criterio." : "Cargando la colección..." }
              </div>
            }
          </div>
        </animated.div>
      </div>
    </>
  );

};

export default Collection;