import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useParams, useHistory } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/firestore';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';
import { ProductContext } from '../Context/ProductContext';

import { errorLog } from '../Helpers/errorLog';

import './HistoryCollection.css';

const truncate = require('lodash.truncate');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const HistoryCollection = ({ windowSize, setNavBg, setWhatsappBtn, incrementProgressLoad, setShowProductOverlay, setOverlayProduct }) => {

  const collectionRef = useRef();

  const { collectionID } = useParams();

  const { showNotification } = useContext(NotificationContext);

  const { historyCollections, loadedHistory } = useContext(ProductContext);

  const history = useHistory();

  const { setWhiteNav, webP } = useContext(TransitionContext);

  //const { transitionTo } = useBubblesTransition();

  const [{ y }, setY] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    setWhiteNav(false);

    if (historyCollections.length > 0 && collectionID && historyCollections.some(collection => collection.id === collectionID)) {
      incrementProgressLoad(100);
    } else if (!collectionID || (loadedHistory && historyCollections.length === 0)) {
      history.push('/');
    }

    if (document.getElementById("collectionSections")) document.getElementById("collectionSections").style.height = `${collectionRef.current.scrollHeight}px`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, historyCollections, loadedHistory, collectionID]);

  const [productGroups, setProductGroups] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState(false);

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  let loadingMoreProducts = useRef();
  if (!loadingMoreProducts.current) loadingMoreProducts.current = null;

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection("products")
      .where('collectionID', '==', collectionID)
      .where('active', '==', true)
      .where('history', '==', true)
      .orderBy('random', 'desc')
      .limit(6)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];  

          const mProducts = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setProductGroups([mProducts]);

          if (querySnapshot.docs.length < 6) {
            loadingMoreProducts.current = true;
          }

          sleep(500).then(() => {
            if (document.getElementById("collectionSections")) document.getElementById("collectionSections").style.height = `${collectionRef.current.scrollHeight}px`;
          })
        } else {
          setProductGroups([]);
          sleep(500).then(() => {
            if (document.getElementById("collectionSections")) document.getElementById("collectionSections").style.height = `${collectionRef.current.scrollHeight}px`;
          })
        }

        setLoadedProducts(true);
      })
      .catch(error => {
        console.log(error);
        errorLog("Initial product load", "none", "get", "HistoryCollection", error.message);
        setLoadedProducts(true);
        showNotification('Error', 'Ha ocurrido un error cargando los datos de los productos. Por favor, vuelva a intentar.', 'error');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreProducts = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      firestore.collection("products")
        .where('collectionID', '==', collectionID)
        .where('active', '==', true)
        .where('history', '==', true)
        .orderBy('random', 'desc')
        .startAfter(nextPointer.current)
        .limit(6)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

            const mProducts = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setProductGroups(pg => pg.concat([mProducts]));

            if (querySnapshot.docs.length < 6) {
              loadingMoreProducts.current = true;
            } else {
              loadingMoreProducts.current = false;
            }

            sleep(500).then(() => {
              if (document.getElementById("collectionSections")) document.getElementById("collectionSections").style.height = `${collectionRef.current.scrollHeight}px`;
            })
          }
        })
        .catch((error) => {
          errorLog("Loading more products", "none", "get", "HistoryCollection", error.message);
          showNotification('Error', 'Ha ocurrido un error cargando los datos de los productos. Por favor, vuelva a intentar.', 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const onScroll = (e) => {
    setY({ y: e.target.scrollTop, config: config.slow });

    if (e.target.scrollTop > windowSize.height - 100) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }

    if (e.target.scrollTop > windowSize.height * 1.5) {
      setWhatsappBtn(false);
    } else {
      setWhatsappBtn(true);
    }

    if (e.target.scrollTop > e.target.scrollHeight / 2 && nextPointer.current && !loadingMoreProducts.current) {
      loadingMoreProducts.current = true;
      loadMoreProducts();
    }
  }

  const phoneScreen = windowSize.width < 768;

  //const [collectionTransition, setCollectionTransition] = useState(false);

  /*const collectionTransitionProps = useSpring({ 
    from: { width: 'calc(70vw)', bottom: 'calc(-105vh)' },
    to: { width: collectionTransition ? 'calc(100vw)' : 'calc(70vw)', bottom: collectionTransition ? 'calc(0vh)' : exploreMore ? 'calc(-95vh)' : 'calc(-105vh)' },
    config: { duration: 1200, easing: easeExpInOut },
    onRest: () => triggerTransition()
  });*/

  /*const triggerTransition = () => {
    if (collectionTransition) {
      setWhiteNav(true);
      history.push('/coleccion');
    }
  }*/

  const hoverProduct = (groupIndex, index, status) => {
    if (status && document.getElementById(`innerProductImg_${groupIndex}_${index}`)) {
      document.getElementById(`innerProductImg_${groupIndex}_${index}`).style.transform = `scale(1.15)`;
    } else if (document.getElementById(`innerProductImg_${groupIndex}_${index}`)) {
      document.getElementById(`innerProductImg_${groupIndex}_${index}`).style.transform = `scale(1)`;
    }
  }

  const openProduct = (product) => {
    setOverlayProduct(product);

    sleep(100).then(() => {
      setShowProductOverlay(true);
    })
  }

  const mobileScreen = windowSize.width <= 1024;

  const getDisplayImage = product => {
    const allImages = product ? product.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images.filter(image => !image.video))}), { images: [] }) : [];

    if (allImages.images.length > 0) {
      if (mobileScreen && allImages.images[0].mobile) {
        return allImages.images[0].mobile.url;
      } else if (webP === 'webp' && allImages.images[0].webp) {
        return allImages.images[0].webp.url;
      } else if (allImages.images[0].jpg) {
        return allImages.images[0].jpg.url;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const getCollectionDisplayImage = collection => {
    if (!collection) return null;

    if (mobileScreen && collection.image && collection.image.mobile) {
      return collection.image.mobile.url;
    } else if (webP === 'webp' && collection.image && collection.image.webp) {
      return collection.image.webp.url;
    } else if (collection.image && collection.image.jpg) {
      return collection.image.jpg.url;
    } else {
      return null;
    }
  }

  return (
    <>
      <div id="collectionScrollArea" style={{ height: windowSize.height }} className="scrollArea" onScroll={onScroll}>
        <div id="collectionSections" className="sections">
          <div className="newInMainOverlay" style={{ opacity: 0 }}>
            <div className="historyHeader">
              <p className="historyHeaderTitle">historia</p>
            </div>
            <div className="productsContainer">
              { loadedProducts && productGroups.length > 0 
              ? productGroups.map((group, index) => 
                  <div key={index} className="productGroupContainer">
                    <div className="firstMainContainer overlayMainProduct" onClick={() => openProduct(group[0])} onMouseEnter={() => hoverProduct(index, 0, true)} onMouseLeave={() => hoverProduct(index, 0, false)} />
                    { group[1]
                    ? <div className="doubleContainer">
                        <div className="doubleContainerInner overlayDoubleProduct" onClick={() => openProduct(group[1])} onMouseEnter={() => hoverProduct(index, 1, true)} onMouseLeave={() => hoverProduct(index, 1, false)} />
                        { group[2]
                        ? <div className="doubleContainerInner overlayDoubleProduct" onClick={() => openProduct(group[2])} onMouseEnter={() => hoverProduct(index, 2, true)} onMouseLeave={() => hoverProduct(index, 2, false)} />
                        : null
                        }
                      </div>
                    : null
                    }
                    { group[3]
                    ? <div className="firstMainContainer overlayMainProduct" onClick={() => openProduct(group[3])} onMouseEnter={() => hoverProduct(index, 3, true)} onMouseLeave={() => hoverProduct(index, 3, false)} />
                    : null
                    }
                    { group[4]
                    ? <div className="doubleContainer">
                        <div className="doubleContainerInner overlayDoubleProduct" onClick={() => openProduct(group[4])} onMouseEnter={() => hoverProduct(index, 4, true)} onMouseLeave={() => hoverProduct(index, 4, false)} />
                        { group[5]
                        ? <div className="doubleContainerInner overlayDoubleProduct" onClick={() => openProduct(group[5])} onMouseEnter={() => hoverProduct(index, 5, true)} onMouseLeave={() => hoverProduct(index, 5, false)} />
                        : null
                        }
                      </div>
                    : null
                    }
                  </div>
                )
              : <div className="newInLoadingContainer">
                  { loadedProducts ? "No hay productos disponibles en este momento." : "Cargando la colección..." }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="homeOuter" style={{ height: windowSize.height }}>
        <animated.div className="collectionContainer" ref={collectionRef} style={{ top: y.to(value => -value) }}>
          <div className="historyHeader">
            <p className="historyHeaderTitle">{historyCollections.find(collection => collection.id === collectionID) ? historyCollections.find(collection => collection.id === collectionID).name : ''}</p>
          </div>
          <div className="singleHistoryCollectionHeader">
            <img src={getCollectionDisplayImage(historyCollections.find(collection => collection.id === collectionID)) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="singleHistoryCollectionImg" />
          </div>
          <div className="productsContainer">
            { loadedProducts && productGroups.length > 0 
            ? productGroups.map((group, index) => 
                <div key={index} className="productGroupContainer">
                  <div className="firstMainContainer">
                    <div className="firstMainImgWrapper">
                      {!group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                      ? <img id={`innerProductImg_${index}_0`} src={getDisplayImage(group[0]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="firstMainImg" />
                      : <div key={group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                          <video autoPlay muted playsInline loop id={group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                            <source src={`${group[0].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                          </video>
                        </div>
                      }
                    </div>
                    <div className="firstMainInfo">
                      <p className="firstMainInfoTitle">{group[0].name}</p>
                      <p className="firstMainInfoDesc">{truncate(group[0].description, { 'length': phoneScreen ? 120 : 240 })}</p>
                    </div>
                  </div>
                  { group[1]
                  ? <div className="doubleContainer">
                      <div className="doubleContainerInner">
                        <div className="doubleContainerImgWrapper">
                          {!group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                          ? <img id={`innerProductImg_${index}_1`} src={getDisplayImage(group[1]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                          : <div key={group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                              <video autoPlay muted playsInline loop id={group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                <source src={`${group[1].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                              </video>
                            </div>
                          }
                        </div>
                        <p className="doubleContainerName">{group[1].name}</p>
                      </div>
                      { group[2]
                      ? <div className="doubleContainerInner">
                          <div className="doubleContainerImgWrapper">
                            {!group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                            ? <img id={`innerProductImg_${index}_2`} src={getDisplayImage(group[2]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                            : <div key={group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                                <video autoPlay muted playsInline loop id={group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                  <source src={`${group[2].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                                </video>
                              </div>
                            }
                          </div>
                          <p className="doubleContainerName">{group[2].name}</p>
                        </div>
                      : null
                      }
                    </div>
                  : null
                  }
                  { group[3]
                  ? <div className="firstMainContainer">
                      <div className="secondMainInfo">
                        <p className="firstMainInfoTitle" style={{ alignSelf: 'flex-end', textAlign: 'right' }}>{group[3].name}</p>
                        <p className="firstMainInfoDesc" style={{ textAlign: 'right' }}>{truncate(group[3].description, { 'length': phoneScreen ? 120 : 240 })}</p>
                      </div>
                      <div className="firstMainImgWrapper">
                        {!group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                        ? <img id={`innerProductImg_${index}_3`} src={getDisplayImage(group[3]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="firstMainImg" />
                        : <div key={group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                            <video autoPlay muted playsInline loop id={group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                              <source src={`${group[3].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                            </video>
                          </div>
                        }
                      </div>
                    </div>
                  : null
                  }
                  { group[4]
                  ? <div className="doubleContainer">
                      <div className="doubleContainerInner">
                        <div className="doubleContainerImgWrapper">
                          {!group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                          ? <img id={`innerProductImg_${index}_4`} src={getDisplayImage(group[4]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                          : <div key={group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                              <video autoPlay muted playsInline loop id={group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                <source src={`${group[4].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                              </video>
                            </div>
                          }
                        </div>
                        <p className="doubleContainerName">{group[4].name}</p>
                      </div>
                      { group[5]
                      ? <div className="doubleContainerInner">
                          <div className="doubleContainerImgWrapper">
                            {!group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].video
                            ? <img id={`innerProductImg_${index}_5`} src={getDisplayImage(group[5]) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="doubleContainerImg" />
                            : <div key={group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideoContainer">
                                <video autoPlay muted playsInline loop id={group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].name} className="productOverlayVideo">
                                  <source src={`${group[5].options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }).images[0].url}`} type="video/mp4" />
                                </video>
                              </div>
                            }
                          </div>
                          <p className="doubleContainerName">{group[5].name}</p>
                        </div>
                      : null
                      }
                    </div>
                  : null
                  }
                </div>
              )
            : <div className="newInLoadingContainer">
                { loadedProducts ? "No hay productos disponibles con este criterio." : "Cargando la colección..." }
              </div>
            }
          </div>
        </animated.div>
      </div>
    </>
  );

};

export default HistoryCollection;