import React from 'react';
import { useSpring, animated } from 'react-spring';

import './WhatsAppButton.css';

function WhatsAppButton({ windowSize, whatsappBtn }) {

  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=+5491141766820', (windowSize.width > 767 && windowSize.orientation === 'portrait') || (windowSize.width > 1024 && windowSize.orientation === 'landscape') ? '_blank' : '_self');
  }

  const buttonProps = useSpring({ left: !whatsappBtn ? '-15vw' : '0vw' });

  return (
    <animated.div style={buttonProps} className="whatsAppButtonContainer" onClick={() => openWhatsApp()}>
      <img src={require('../assets/images/whatsapp.png')} alt="Botón WhatsApp" className="whatsAppButtonIcon" />
    </animated.div>
  );
}

export { WhatsAppButton };