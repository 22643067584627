import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useSpring, animated } from 'react-spring';
import { easeExpInOut } from 'd3-ease';

import { ShoppingBag } from 'react-feather';

import { Menu } from './Menu';
import { CartOverlay } from './CartOverlay';

import { useBubblesTransition } from '../Hooks/useBubblesTransition';

import './Navbar.css';
import { TransitionContext } from '../Context/TransitionContext';

const Navbar = ({ routerRef, windowSize, white, navBg, setNavBg, cartStatus, setCartStatus }) => {

  const history = useHistory();
  const location = useLocation();

  const { transitionTo } = useBubblesTransition();

  const { setBodyLock } = useContext(TransitionContext);

  const [menuOpen, setMenuOpen] = useState(false);

  const phoneScreen = windowSize.orientation === 'portrait' && windowSize.width < 768;

  const logoProps = useSpring({ filter: menuOpen ? 'brightness(100%) invert(0)' : 'brightness(0%) invert(0)', config: { duration: 500, easing: easeExpInOut }, delay: menuOpen ? 1000 : 300 });

  const navBtnProps = useSpring({ filter: white && !menuOpen ? 'invert(1)' : 'invert(0)', backgroundColor: navBg && !menuOpen ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)', borderColor: menuOpen ? '#a6513f' : 'rgba(51,51,51,0.1)', color: menuOpen ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,1)', config: { duration: 500, easing: easeExpInOut }, delay: menuOpen ? 300 : 0 });
  const logoBtnProps = useSpring({ filter: (white && !menuOpen) || (location.pathname === '/contacto' && !phoneScreen) ? 'invert(1)' : 'invert(0)', backgroundColor: navBg && !menuOpen ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)', borderColor: menuOpen ? 'rgba(51,51,51,0)' : 'rgba(51,51,51,0.1)', config: { duration: 500, easing: easeExpInOut }, delay: menuOpen ? 300 : 0 });

  const handleClickHome = () => {
    if (menuOpen) {
      window.scrollTo({ top: 0 });
      setNavBg(false);
      history.push('/');
      setMenuOpen(false);
      setBodyLock(false);
    } else if (location.pathname !== '/') {
      transitionTo('/');
    }
  }

  const toggleMenu = () => {
    if (menuOpen) setBodyLock(false);
    setMenuOpen(m => !m);
  };

  return (
    <animated.div className="navbar">
      <CartOverlay routerRef={routerRef} windowSize={windowSize} cartStatus={cartStatus} setCartStatus={setCartStatus} />
      <animated.div className='logoContainer' style={logoBtnProps}>
        <animated.img style={logoProps} onClick={handleClickHome} src={require('../assets/images/logoVDLC_1.png')} alt="Logo VDLC" className="logo" />
      </animated.div>
      <div className='navBtns'>
        <animated.div className='navBtnContainer shoppingGlyphContainer' style={navBtnProps} onMouseEnter={() => setCartStatus('peek')} onClick={() => setCartStatus('peek')}>
          <ShoppingBag className='shoppingGlyph' />
        </animated.div>
        <animated.div className='navBtnContainer menuNavBtn' onClick={toggleMenu} style={navBtnProps}>
          <p className='menuBtnText menuBtnAlignLeft'>ME</p>
          <p className='menuBtnText menuBtnAlignRight'>NU</p>
        </animated.div>
      </div>
      <Menu windowSize={windowSize} menuOpen={menuOpen} setMenuOpen={setMenuOpen} setNavBg={setNavBg} />
    </animated.div>
  );
};

export { Navbar };