import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import { useBubblesTransition } from '../Hooks/useBubblesTransition';

import './HomePlusButton.css';

const HomePlusButton = () => {
  const { transitionTo } = useBubblesTransition();

  const [hovered, setHovered] = useState(false);

  const hoveredProps = useSpring({ transform: hovered ? 'scale(0.9)' : 'scale(1)', opacity: hovered ? 0.8 : 1 });

  const vertProps = useSpring({ height: hovered ? '80%' : '0%' });
  const horProps = useSpring({ width: hovered ? '80%' : '0%' });

  return (
    <animated.div style={hoveredProps} className="homeSubheaderGoBtn" onClick={() => transitionTo('/coleccion')} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <animated.div style={vertProps} className="homeGoBtnVert" />
      <animated.div style={horProps} className="homeGoBtnHor" />
    </animated.div>
  );
};

export { HomePlusButton };