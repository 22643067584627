import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useHistory } from "react-router-dom";

import { useBubblesTransition } from '../Hooks/useBubblesTransition';

import { TransitionContext } from '../Context/TransitionContext';
import { ProductContext } from '../Context/ProductContext';

import './History.css';

const History = ({ windowSize, setNavBg, setWhatsappBtn, incrementProgressLoad }) => {

  const collectionRef = useRef();

  const { historyCollections, loadedHistory } = useContext(ProductContext);

  const history = useHistory();

  const { setWhiteNav, webP } = useContext(TransitionContext);

  const { transitionTo } = useBubblesTransition();

  const [{ y }, setY] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    if (historyCollections.length > 0) {
      incrementProgressLoad(100);
    } else if (loadedHistory && historyCollections.length === 0) {
      history.push('/');
    }

    setWhiteNav(false);

    if (document.getElementById("collectionSections")) document.getElementById("collectionSections").style.height = `${collectionRef.current.scrollHeight}px`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, historyCollections, loadedHistory]);

  const onScroll = (e) => {
    setY({ y: e.target.scrollTop, config: config.slow });

    if (e.target.scrollTop > windowSize.height - 100) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }

    if (e.target.scrollTop > windowSize.height * 1.5) {
      setWhatsappBtn(false);
    } else {
      setWhatsappBtn(true);
    }
  }

  const desktopScreen = windowSize.width > 1024 || windowSize.orientation === "landscape";

  const [exploreMore, setExploreMore] = useState(false);

  //const [collectionTransition, setCollectionTransition] = useState(false);

  /*const collectionTransitionProps = useSpring({ 
    from: { width: 'calc(70vw)', bottom: 'calc(-105vh)' },
    to: { width: collectionTransition ? 'calc(100vw)' : 'calc(70vw)', bottom: collectionTransition ? 'calc(0vh)' : exploreMore ? 'calc(-95vh)' : 'calc(-105vh)' },
    config: { duration: 1200, easing: easeExpInOut },
    onRest: () => triggerTransition()
  });*/

  /*const triggerTransition = () => {
    if (collectionTransition) {
      setWhiteNav(true);
      history.push('/coleccion');
    }
  }*/

  const hoverCollection = (index, status) => {
    if (status && document.getElementById(`imgCollection_${index}`)) {
      document.getElementById(`imgCollection_${index}`).style.filter = `brightness(0.7)`;
    } else if (document.getElementById(`imgCollection_${index}`)) {
      document.getElementById(`imgCollection_${index}`).style.filter = `brightness(1)`;
    }
  }

  const openCollection = (collectionID) => {
    transitionTo('/anterior/' + collectionID);
  }

  const mobileScreen = windowSize.width <= 1024;

  const getDisplayImage = collection => {
    if (mobileScreen && collection.image && collection.image.mobile) {
      return collection.image.mobile.url;
    } else if (webP === 'webp' && collection.image && collection.image.webp) {
      return collection.image.webp.url;
    } else if (collection.image && collection.image.jpg) {
      return collection.image.jpg.url;
    } else {
      return null;
    }
  }

  return (
    <>
      <div id="collectionScrollArea" style={{ height: windowSize.height }} className="scrollArea" onScroll={onScroll}>
        <div id="collectionSections" className="sections">
          <div className="exploreMoreOverlay" onClick={() => transitionTo('/contacto')} onMouseEnter={desktopScreen ? () => setExploreMore(true) : null} onMouseLeave={desktopScreen ? () => setExploreMore(false) : null} />
          {/*<animated.img src={require('../assets/images/renaissance.jpg')} alt="Fresco del renacimiento" className="homeTransitionImg" style={collectionTransitionProps} />*/}
          <div className="newInMainOverlay" style={{ opacity: 0 }}>
            <div className="historyHeader">
              <p className="historyHeaderTitle">historia</p>
            </div>
            <div className="historyCollectionsContainer">
              { !historyCollections.some(collection => !collection.image) && historyCollections.map((collection, index) => 
                <div key={collection.id} className="historyCollectionContainer collectionContainerClickable" onClick={() => openCollection(collection.id)} onMouseEnter={() => hoverCollection(index, true)} onMouseLeave={() => hoverCollection(index, false)}>
                  <img src={require('../assets/images/closeButtonCrop.png')} alt="Coleccion VDLC" className="historyCollectionImg" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="homeOuter" style={{ height: windowSize.height }}>
        <animated.div className="collectionContainer" ref={collectionRef} style={{ top: y.to(value => -value) }}>
          <div className="historyHeader">
            <p className="historyHeaderTitle">historia</p>
          </div>
          <div className="historyCollectionsContainer">
            { !historyCollections.some(collection => !collection.image) && historyCollections.map((collection, index) => 
              <div key={collection.id} className="historyCollectionContainer">
                <img id={`imgCollection_${index}`} src={getDisplayImage(collection) || require('../assets/images/homeDecoration.jpg')} alt="Coleccion VDLC" className="historyCollectionImg" />
                <p className="historyCollectionContainerTitle">{collection.name}</p>
              </div>  
            )}
          </div>
          <div className="homeNextContainer newInNextContainer">
            <div className="homeNextTitleContainer">
              <p className="homeNextTitle newInNextTitle">CONTACTO</p>
              <p className="homeNextTitle newInNextTitle"></p>
            </div>
            <animated.div className="homeNextLine newInNextLine" style={{ width: exploreMore ? 'calc(0vw)' : 'calc(100vw)' }} />
          </div>
        </animated.div>
      </div>
    </>
  );

};

export default History;