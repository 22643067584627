import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import Home from './Screens/Home';
import Collection from './Screens/Collection';
import History from './Screens/History';
import HistoryCollection from './Screens/HistoryCollection';
// import NewIn from './Screens/NewIn';
import Contact from './Screens/Contact';
import Account from './Screens/Account';
import NotFound from './Screens/NotFound';
import PaymentSuccess from './Screens/PaymentSuccess';
import PaymentFailure from './Screens/PaymentFailure';

import { Navbar } from './Components/Navbar';
import { Preloader } from './Components/Preloader';
import { NotificationBox } from './Components/NotificationBox';
import { SingleProductOverlay } from './Components/SingleProductOverlay';
import { WhatsAppButton } from './Components/WhatsAppButton';

import { BubblesTransitionOverlay } from './Components/BubblesTransitionOverlay';

import { TransitionContext } from './Context/TransitionContext';
import { NotificationContext } from './Context/NotificationContext';
import { ProductContext } from './Context/ProductContext';
import { UserContext } from './Context/UserContext';

import { errorLog } from './Helpers/errorLog';

import './App.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

var firebaseConfig = {
  apiKey: "AIzaSyC6FTVuxan3QHxdDIVuHcIzrMFyDOD2UjM",
  authDomain: "vdlc-store.firebaseapp.com",
  databaseURL: "https://vdlc-store.firebaseio.com",
  projectId: "vdlc-store",
  storageBucket: "vdlc-store.appspot.com",
  messagingSenderId: "773699075327",
  appId: "1:773699075327:web:f3ef09cec50f35b4e660ca"
};

firebase.initializeApp(firebaseConfig);

const App = () => {

  const [progressLoad, setProgressLoad] = useState(0);

  const jumpStartProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  });

  const [windowSize, setWindowSize] = useState({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});

  const [whiteNav, setWhiteNav] = useState(false);
  const [navBg, setNavBg] = useState(false);
  const [whatsappBtn, setWhatsappBtn] = useState(true);

  const [shoppingCart, setShoppingCart] = useState([]);

  const [webP, setWebP] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('shoppingCart')) setShoppingCart(JSON.parse(localStorage.getItem('shoppingCart')));

    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) {
        setWebP('png');
        return false;
      }
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
      
      setWebP(supportsWebP ? 'webp' : 'png');
    }

    checkForWebP();
  }, []);

  useEffect(() => {
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  }, [shoppingCart]);

  useEffect(() => {
    const onPopButton = () => {
      setNavBg(false);
    }

    window.addEventListener('popstate', onPopButton);

    return () => window.removeEventListener('popstate', onPopButton);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const firestore = firebase.firestore();

        const docRef = firestore.collection("users").doc(user.uid);

        docRef.get()
          .then(function(doc) {
            if (!doc.exists) {
              docRef.set({ email: user.email }, { merge: true });
            }
          })
          .catch(error => {
            errorLog("Retrieving user info", { userID: user.uid }, "get", "App", error.message);
          });

        setSignedIn(true);
      } else {
        setSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {  
    setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});
    
    if (window.location.pathname.startsWith('/share/')) {
      const productID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

      const firestore = firebase.firestore();

      firestore.collection('products').doc(productID)
        .get()
        .then(doc => {
          if (doc.exists && doc.data().active) {
            setOverlayProduct({ ...doc.data(), id: doc.id });
            setShowProductOverlay(true);
          } else {
            errorLog("Retrieving product info from share", { productID: productID }, "get", "App", 'none');
            showNotification('Error', 'No hay ningún producto activo con ese ID. Por favor, vuelva a intentar.', 'error');
          }
        })
        .catch(error => {
          errorLog("Retrieving product info from share", { productID: productID }, "get", "App", error.message);
          showNotification('Error', 'No hay ningún producto activo con ese ID. Por favor, vuelva a intentar.', 'error');
        })
    }
  }, []);

  const [showTransitionOverlay, setShowTransitionOverlay] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({height: window.document.documentElement.clientHeight, width: window.document.documentElement.clientWidth, orientation: window.document.documentElement.clientWidth > window.document.documentElement.clientHeight ? 'landscape' : 'portrait'});
    }
    
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const [historyCollections, setHistoryCollections] = useState([]);
  const [loadedHistory, setLoadedHistory] = useState(false);

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection('collections').where('active', '==', true).where('history', '==', true).orderBy('timestamp', 'desc')
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setHistoryCollections(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, image: null })));

          const uploadPromises = querySnapshot.docs.map(async doc => {
            
            const collectionID = doc.id;

            const random = uuidv4();

            let lower = null;
            let higher = null;

            await firestore.collection('products')
              .where('active', '==', true)
              .where('history', '==', true)
              .where('collectionID', '==', collectionID)
              .where('random', '<=', random)
              .orderBy('random', 'desc')
              .limit(1)
              .get()
                .then(querySnapshot => {
                  if (!querySnapshot.empty) {
                    lower = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                  }
                })
                .catch(error => {
                  //console.log(error);
                });

            await firestore.collection('products')
              .where('active', '==', true)
              .where('history', '==', true)
              .where('collectionID', '==', collectionID)
              .where('random', '>', random)
              .orderBy('random', 'desc')
              .limit(1)
              .get()
                .then(querySnapshot => {
                  if (!querySnapshot.empty) {
                    higher = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
                  }
                })
                .catch(error => {
                  //console.log(error);
                });

            if (lower) {
              const allImages = lower.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images.filter(image => image.jpg && !image.video))}), { images: [] });
              
              setHistoryCollections(hc => hc.map(coll => coll.id === collectionID ? { ...coll, image: allImages.images[0] } : coll));
              setLoadedHistory(true);
            } else if (higher) {
              const allImages = higher.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images.filter(image => image.jpg && !image.video))}), { images: [] });
              
              setHistoryCollections(hc => hc.map(coll => coll.id === collectionID ? { ...coll, image: allImages.images[0] } : coll));
              setLoadedHistory(true);
            };
          });
        
          return Promise.all(uploadPromises);
        } else {
          setLoadedHistory(true);
          return true;
        }
      })
      .then(() => {
        //console.log("All History Collections populated");
      })
      .catch(error => {
        //console.log(error);
        setLoadedHistory(true);
        errorLog("Retrieving history collections", 'none', "get", "App", error.message);
      });
  }, []);

//   const [newCollectionID, setNewCollectionID] = useState(null);

//   useEffect(() => {
//     const firestore = firebase.firestore();

//     firestore.collection('collections').where('active', '==', true).where('history', '==', false).orderBy('timestamp', 'desc').limit(1)
//       .get()  
//       .then(querySnapshot => {
//         if (!querySnapshot.empty) {
//           setNewCollectionID(querySnapshot.docs[0].id);
//         }
//       })
//       .catch(error => {
//         //console.log(error);
//         errorLog("Retrieving latest collection", 'none', "get", "App", error.message);
//         showNotification('Error', 'Ha ocurrido un error cargando los datos de la página. Por favor, vuelva a intentar.', 'error');
//       });
//   }, []);

  const setBodyLock = (status) => {
    if (status) {
      document.getElementsByTagName("body")[0].style.height = `${windowSize.height}px`;
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.height = "auto";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }

  const incrementProgressLoad = (increment) => {
    if (progressLoad + increment <= 100) {
      setProgressLoad(p => p + increment);
    }
  }

  const [notification, setNotification] = useState({
    show: false,
    title: 'Test Alert',
    text: 'This is a test alert to see how it works.',
    type: 'info'
  });

  const showNotification = (title, text, type) => {
    setNotification({ show: true, title: title, text: text, type: type });

    sleep(3500).then(() => {
      setNotification({ show: false, title: title, text: text, type: type });
    })
  }

  const [overlayProduct, setOverlayProduct] = useState(null);
  const [showProductOverlay, setShowProductOverlay] = useState(false);

  const [cartStatus, setCartStatus] = useState(null);

  const routerRef = useRef();

  return (
    <animated.div style={jumpStartProps}>
      {!window.location.pathname.startsWith('/pago') ? <Preloader windowSize={windowSize} setBodyLock={setBodyLock} progressLoad={progressLoad} /> : null}
      <NotificationContext.Provider value={{ showNotification: showNotification }}>
        <TransitionContext.Provider value={{ setTransitionOverlay: setShowTransitionOverlay, setBodyLock: setBodyLock, setWhiteNav: setWhiteNav, setNavBg: setNavBg, webP: webP }}>
          <ProductContext.Provider value={{ shoppingCart: shoppingCart, setShoppingCart: setShoppingCart, historyCollections: historyCollections, loadedHistory: loadedHistory }}>
            <UserContext.Provider value={{ signedIn: signedIn, setSignedIn: setSignedIn }}>
              <div id="app" className="appContainer">
                <Router ref={routerRef}>
                  <div className="mainContainer">
                    <NotificationBox windowSize={windowSize} show={notification.show} title={notification.title} text={notification.text} type={notification.type} />

                    <BubblesTransitionOverlay show={showTransitionOverlay} windowSize={windowSize} />

                    <WhatsAppButton windowSize={windowSize} whatsappBtn={whatsappBtn} />

                    <SingleProductOverlay routerRef={routerRef} windowSize={windowSize} show={showProductOverlay} product={overlayProduct} setOverlayProduct={setOverlayProduct} setShowProductOverlay={setShowProductOverlay} setCartStatus={setCartStatus} setBodyLock={setBodyLock} />

                    <Navbar routerRef={routerRef} windowSize={windowSize} white={whiteNav} navBg={navBg} setNavBg={setNavBg} cartStatus={cartStatus} setCartStatus={setCartStatus} />

                    <Switch>
                      <Route exact path="/">
                        <Home windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} />
                      </Route>
                      <Route path="/pago/ok">
                        <PaymentSuccess windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} />
                      </Route>
                      <Route path="/pago/error">
                        <PaymentFailure windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} />
                      </Route>
                      <Route path="/cuenta">
                        <Account windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} />
                      </Route>
                      <Route path="/historia">
                        <History windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} historyCollections={historyCollections} />
                      </Route>
                      <Route path="/anterior/:collectionID">
                        <HistoryCollection windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} setOverlayProduct={setOverlayProduct} setShowProductOverlay={setShowProductOverlay} />
                      </Route>
                      <Route path="/coleccion">
                        <Collection windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} setOverlayProduct={setOverlayProduct} setShowProductOverlay={setShowProductOverlay} />
                      </Route>
                      {/* <Route path="/new-in">
                        <NewIn windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} collection={newCollectionID} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} setOverlayProduct={setOverlayProduct} setShowProductOverlay={setShowProductOverlay} />
                      </Route> */}
                      <Route path="/contacto">
                        <Contact windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} />
                      </Route>
                      <Route path="/share">
                        <Home windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} setNavBg={setNavBg} setWhatsappBtn={setWhatsappBtn} />
                      </Route>
                      <Route path="/">
                        <NotFound windowSize={windowSize} incrementProgressLoad={incrementProgressLoad} />
                      </Route>
                    </Switch>
                  </div>
                </Router>
              </div>
            </UserContext.Provider>
          </ProductContext.Provider>
        </TransitionContext.Provider>
      </NotificationContext.Provider>
    </animated.div>
  );
}

export default App;
