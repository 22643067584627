import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { CheckCircle, Printer } from 'react-feather';

import { TransitionContext } from '../Context/TransitionContext';

import { errorLog } from '../Helpers/errorLog';

import './PaymentSuccess.css';

const provinces = [
  { value: 'buenosAires', label: 'Buenos Aires' },
  { value: 'catamarca', label: 'Catamarca' },
  { value: 'caba', label: 'Ciudad Autónoma de Buenos Aires' },
  { value: 'chaco', label: 'Chaco' },
  { value: 'chubut', label: 'Chubut' },
  { value: 'cordoba', label: 'Córdoba' },
  { value: 'corrientes', label: 'Corrientes' },
  { value: 'entreRios', label: 'Entre Ríos' },
  { value: 'formosa', label: 'Formosa' },
  { value: 'jujuy', label: 'Jujuy' },
  { value: 'laPampa', label: 'La Pampa' },
  { value: 'laRioja', label: 'La Rioja' },
  { value: 'mendoza', label: 'Mendoza' },
  { value: 'misiones', label: 'Misiones' },
  { value: 'neuquen', label: 'Neuquén' },
  { value: 'rioNegro', label: 'Río Negro' },
  { value: 'salta', label: 'Salta' },
  { value: 'sanJuan', label: 'San Juan' },
  { value: 'sanLuis', label: 'San Luis' },
  { value: 'santaCruz', label: 'Santa Cruz' },
  { value: 'santaFe', label: 'Santa Fe' },
  { value: 'santiagoDelEstero', label: 'Santiago del Estero' },
  { value: 'tierraDelFuego', label: 'Tierra del Fuego' },
  { value: 'tucuman', label: 'Tucumán' },
];

const PaymentSuccess = ({ windowSize, incrementProgressLoad }) => {

  const { setWhiteNav } = useContext(TransitionContext);

  const [order, setOrder] = useState(null);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    setWhiteNav(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.clear();

    incrementProgressLoad(100);

    if (window.location.search.includes('external_reference')) {
      const params = window.location.search.split('&');

      const orderID = params.find(param => param.startsWith('external_reference')).split('=')[1];

      axios({
        method:'post',
        url: "https://us-central1-vdlc-store.cloudfunctions.net/getOrderInfo",
        data: {
          orderID: orderID,
        }
      })
        .then((response) => {
          setOrder(response.data);
          setInfoLoaded(true);
        })
        .catch((error) => {
          errorLog("Retrieving order info", { orderID: orderID }, "postAxios", "PaymentSuccess", error.message);
          setInfoLoaded(true);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="paymentSuccessOuter" style={{ height: windowSize.height }}>
      {infoLoaded && order 
      ? <div className="paymentSuccessContainer">
          <div className="paymentSuccessColumn">
            <div className="orderConfirmationContainer">
              <p className="orderConfirmationTitle">Tu orden ha sido confirmada.</p>
              <p className="orderConfirmationSub">Muchas gracias por tu compra, {order.client.name.split(' ')[0]}. Te enviaremos un correo a <strong>{order.client.email}</strong> con toda la información.</p>
              <a href={order.confirmation.url} target="_blank" rel="noopener noreferrer" className="orderConfirmationButton">
                <Printer className="orderConfirmationButtonIcon" />
                DESCARGAR COMPROBANTE
              </a>
            </div>
            <div className="orderConfirmationAddressContainer">
              <p className="orderConfirmationAddressTitle">{order.shipping.pickup ? 'Retiro en local' : 'Dirección de envio'}</p>
              {!order.shipping.pickup && <p className="orderConfirmationAddressSub">
                <span>{order.address.street + " " + order.address.streetNumber + " - " + order.address.floor + ","}</span><br/>
                {provinces.find(item => item.value === order.address.province) ? order.address.local + ", " +  provinces.find(item => item.value === order.address.province).label + ", " + order.address.zipCode : ''}
              </p>}
            </div>
          </div>
          <div className="paymentSuccessProductsColumn">
            <p className="orderConfirmationAddressTitle" style={{ alignSelf: 'flex-start' }}>Productos</p>
            { order.items.map((item, index) => 
              <div key={item.id} className="checkoutConfirmationProductsLine">
                <p className="checkoutConfirmationProductsQuantity" style={{ color: '#333' }}>{item.quantity + "   x "}</p>
                <div className="checkoutConfirmationProductInfo">
                  <p className="checkoutConfirmationProductTitle" style={{ color: '#333' }}>{item.name}</p>
                  <div className="checkoutConfirmationProducExtra">
                    <p className="checkoutConfirmationProductSub" style={{ color: '#333' }}>{item.option}</p>
                    <p className="checkoutConfirmationProductSize" style={{ color: '#333' }}>{"Talle: " + item.size}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      : infoLoaded 
        ? <div className="orderConfirmationLoadingMessageContainer">
            <CheckCircle className="successLoadingIcon" />
            Hubo un error al cargar la información. Por favor, vuelve a cargar esta página. Si el problema persiste, comunícate con nosotros.
          </div>
        : <div className="orderConfirmationLoadingMessageContainer">
            <CheckCircle className="successLoadingIcon" />
            Cargando...
          </div>
      }
    </div>
  );
};

export default PaymentSuccess;