import React from 'react';
import { useSpring, animated } from 'react-spring';

import { Info, AlertCircle, CheckCircle } from 'react-feather';

import './NotificationBox.css';

const NotificationBox = ({ windowSize, show, title, text, type }) => {

  const boxProps = useSpring({ opacity: show ? 1 : 0, backgroundColor: '#131414' });

  return (
    <animated.div style={boxProps} className="notificationBox">
      { type === 'info'
      ? <Info className="notificationIcon" />
      : type === 'error'
        ? <AlertCircle className="notificationIcon" />
        : <CheckCircle className="notificationIcon" />
      }
      <div className="notificationInfo">
        <p className="notificationTitle">{title}</p>
        <p className="notificationText">{text}</p>
      </div>
    </animated.div>
  );
}

export { NotificationBox };