import React, { useContext, useEffect } from 'react';
import { useSpring, useTrail, animated, config } from 'react-spring';

import { Plus, Minus } from 'react-feather';

import { ProductContext } from '../Context/ProductContext';
import { NotificationContext } from '../Context/NotificationContext';

import { CheckoutContainer } from './CheckoutContainer';

import './CartOverlay.css';
import { TransitionContext } from '../Context/TransitionContext';

const CartOverlay = ({ routerRef, windowSize, cartStatus, setCartStatus }) => {
  const { setBodyLock } = useContext(TransitionContext);

  const { shoppingCart, setShoppingCart } = useContext(ProductContext);
  const { showNotification } = useContext(NotificationContext);

  const desktopScreen = windowSize.width > 1024 || windowSize.orientation === 'landscape';
  const tabletScreen = windowSize.width > 767 && windowSize.orientation === 'portrait';

  useEffect(() => {
    if (cartStatus === 'full') {
      document.getElementById("cartCloseButton").style.opacity = 0;
    } else {
      document.getElementById("cartCloseButton").style.opacity = 1;
    }
  }, [cartStatus]);

  const menuProps = useSpring({
    from: { width: 0 },
    to: { width: cartStatus === 'peek' ? (desktopScreen ? windowSize.width * 0.35 : tabletScreen ? windowSize.width * 0.75 : windowSize.width) : cartStatus === 'full' ? windowSize.width : 0 },
  });

  const trail = useTrail(shoppingCart.length, {
    config: cartStatus === 'peek' ? config.slow : config.default,
    delay: cartStatus === 'peek' ? 200 : 0,
    opacity: cartStatus === 'peek' ? 1 : 0
  });

  const noProductMessageProps = useSpring({ opacity: cartStatus === 'peek' ? 1 : 0, delay: cartStatus === 'peek' ? 200 : 0 });

  const extraInfoProps = useSpring({ opacity: cartStatus === 'peek' ? 1 : 0, delay: cartStatus === 'peek' ? 200 : 0 });

  const checkoutContainerProps = useSpring({ opacity: cartStatus === 'full' ? 1 : 0, pointerEvents: cartStatus === 'full' ? 'all' : 'none', delay: cartStatus === 'full' ? 600 : 0, config: config.slow });

  const formatPrice = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " ARS";
    } catch (e) {
      console.log(e)
    }
  };

  const subtotal = shoppingCart.reduce((accum, curr) => accum + parseInt(curr.price,10) * curr.quantity, 0);

  const handleRemoveProduct = productID => {
    setShoppingCart(shoppingCart.filter(item => item.id !== productID));
  }

  const handleChangeQuantity = (delta, productID) => {
    if ((delta > 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity < shoppingCart.find(item => item.id === productID).availableStock) || (delta < 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity > 1)) {
      setShoppingCart(shoppingCart.map(item => item.id === productID ? { ...item, quantity: item.quantity + delta } : item));
    } else if (delta > 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity === shoppingCart.find(item => item.id === productID).availableStock) {
      showNotification('Lo sentimos', 'No hay suficiente stock para este producto en la cantidad que buscas.', 'error');
    }
  }

  return (
    <animated.div style={menuProps} className="overlayMenu" onMouseLeave={cartStatus !== 'full' ? () => setCartStatus(null) : null}>
      <div className="overlayInner">
        <img id="cartCloseButton" onClick={() => { setBodyLock(false); setCartStatus(null); }} src={require('../assets/images/closeButtonCrop.png')} alt="Boton para cerrar el carrito" className="peekCloseButton" />
        <div className="peekContainer">
          <div className="peekProductList">
            <div className="peekProductListInner">
              {shoppingCart.length > 0 
              ? trail.map(({ opacity }, index) => (
                  <animated.div
                    key={shoppingCart[index].id}
                    className="peekProductItem"
                    style={{ opacity: opacity }}>
                      <img src={shoppingCart[index].image.mobile.url || require('../assets/images/homeDecoration.jpg')} alt={shoppingCart[index].name} className="peekProductImg" />
                      <div className="peekProductInfo">
                        <div className="peekProductInfoMain">
                          <div className="peekProductInfoTitleContainer">
                            <div className="peekProductInfoTitle">{shoppingCart[index].name}</div>
                            <div className="peekProductInfoSub">{shoppingCart[index].option}</div>
                          </div>
                          <img onClick={() => handleRemoveProduct(shoppingCart[index].id)} src={require('../assets/images/minusButton.png')} alt="Boton para remover producto de la lista" className="peekProductRemoveButton" />
                        </div>
                        <div className="peekProductInfoSecondary">
                          <div className="peekProductInfoSecondaryExtra">
                            <div className="peekProductInfoSecondarySize">{"Talle: " + shoppingCart[index].size}</div>
                            <div className="peekProductInfoSecondaryQuantity">
                              Cantidad:
                              <div className="peekProductQuantityContainer">
                                <Minus className="peekProductQuantityIcon" onClick={() => handleChangeQuantity(-1, shoppingCart[index].id)} />
                                <p className="peekProductQuantityText">{shoppingCart[index].quantity}</p>
                                <Plus className="peekProductQuantityIcon" onClick={() => handleChangeQuantity(1, shoppingCart[index].id)}/>
                              </div>
                            </div>
                          </div>
                          <div className="peekProductPrice">{formatPrice((parseInt(shoppingCart[index].price,10) * shoppingCart[index].quantity)).toString()}</div>
                        </div>
                      </div>
                  </animated.div>
                )) 
              : <animated.div style={noProductMessageProps} className="peekProductListEmpty">
                  <p className="peekProductListEmptyText">No tienes productos en tu carrito en este momento.</p>
                </animated.div>
              }
            </div>
          </div>
          <animated.div style={extraInfoProps} className="peekInfoContainer">
            <p className="peekSubtotal">SUBTOTAL:<br/>{formatPrice(subtotal)}</p>
            <button onClick={() => setCartStatus('full')} disabled={shoppingCart.length === 0} className="peekButton">FINALIZAR COMPRA</button>
          </animated.div>
        </div>
        <animated.div style={checkoutContainerProps} className="checkoutContainer">
          <CheckoutContainer routerRef={routerRef} windowSize={windowSize} cartStatus={cartStatus} setCartStatus={setCartStatus} />
        </animated.div>
      </div>
    </animated.div>
  );
};

export { CartOverlay };