import React, { useContext, useEffect } from 'react';

import { TransitionContext } from '../Context/TransitionContext';

import './Contact.css';

const Contact = ({ windowSize, incrementProgressLoad }) => {

  const { setWhiteNav, webP } = useContext(TransitionContext);

  useEffect(() => {
    setWhiteNav(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEmail = () => {
    window.open('mailto:verodelacanaltienda@gmail.com');
  }

  const openWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=+5491141766820', '_blank');
  }

  const mobileScreen = windowSize.width <= 1024;

  return (
    <div className="contactContainer" style={{ height: windowSize.height }}>
      <div className="contactImgWrapper">
        { mobileScreen
        ? <img src={require('../assets/images/contactVDLC.jpg')} style={{ height: windowSize.height }} alt="Coleccion VDLC" className="contactImg" onLoad={() => incrementProgressLoad(100)} onError={() => incrementProgressLoad(100)} />
        : webP ? <img src={require(`../assets/images/${webP === 'webp' ? 'contactVDLC.webp' : 'contactVDLC.jpg'}`)} style={{ height: windowSize.height }} alt="Coleccion VDLC" className="contactImg" onLoad={() => incrementProgressLoad(100)} onError={() => incrementProgressLoad(100)} /> : null
        }
      </div>
      <div className="contactInfoContainer">
        <p className="contactInfoTitle">Teléfono</p>
        <p className="contactInfoText" style={{ cursor: 'pointer' }} onClick={() => openWhatsApp()}>+54 9 11 4176-6820</p>
        <p className="contactInfoTitle">Email</p>
        <p className="contactInfoText" style={{ cursor: 'pointer' }} onClick={() => sendEmail()}>verodelacanaltienda@gmail.com</p>
        <a style={{ textDecoration: 'none' }} className="contactInfoLink" href="https://www.instagram.com/verodelacanaltienda/?hl=es-la" target="_blank" rel="noopener noreferrer">
          <p className="contactInfoTitle" style={{ marginBottom: 0 }}>Instagram</p>
          <div className="contactInfoUnderline" />
        </a>
        <a style={{ textDecoration: 'none' }} className="contactInfoLink" href="https://www.facebook.com/Verodelacanaltienda-102324701508553" target="_blank" rel="noopener noreferrer">
          <p className="contactInfoTitle" style={{ marginBottom: 0 }}>Facebook</p>
          <div className="contactInfoUnderline" />
        </a>
      </div>
    </div>
  );
};

export default Contact;