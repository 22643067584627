import { useContext, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { TransitionContext } from '../Context/TransitionContext';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const blackPages = ["/"];

const useBubblesTransition = () => {

  const { setTransitionOverlay, setBodyLock, setWhiteNav, setNavBg } = useContext(TransitionContext);

  const history = useHistory();

  const transitionTo = useCallback(path => {
    setTransitionOverlay(t => !t);
    setBodyLock(true);
    setNavBg(false);
    if (blackPages.some(p => p === path)) {
      setWhiteNav(false);
    } else {
      setWhiteNav(true);
    }
    
    sleep(1000).then(() => {
      history.push(path);
      window.scrollTo({ top: 0 });
      //setTransitionOverlay(false);
      setBodyLock(false);
    })
  }, [setTransitionOverlay, setBodyLock, setWhiteNav, setNavBg, history]);

  return { transitionTo };
}

export { useBubblesTransition };