import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { easeExpInOut } from 'd3-ease';

import { Info, Tag } from 'react-feather';

import { NotificationContext } from '../Context/NotificationContext';
import { TransitionContext } from '../Context/TransitionContext';
import { ProductContext } from '../Context/ProductContext';

import './SingleProductOverlay.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const SingleProductOverlay = ({ routerRef, windowSize, show, product, setOverlayProduct, setShowProductOverlay, setCartStatus, setBodyLock }) => {

  const mHeight = windowSize.height * 0.90;

  const draggingRef = useRef(false)
  const [{ y }, set] = useSpring(() => ({ y: mHeight }));

  const bind = useDrag(
    ({ first, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      if (first) draggingRef.current = true
      // if this is not the first or last frame, it's a moving frame
      // then it means the user is dragging
      else if (last) draggingRef.current = false

      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) my > mHeight * 0.4 || vy > 0.5 ? close(vy) : open({ canceled });
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else set({ y: my, immediate: false, config: config.stiff })
    },
    { initial: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  )

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({ y: mHeight * 0.2, config: canceled ? config.wobbly : config.stiff });
  }
  const close = (velocity = 0) => {
    set({ y: mHeight * 0.9, config: { ...config.stiff, velocity } });
  }

  const { shoppingCart, setShoppingCart } = useContext(ProductContext);

  const { showNotification } = useContext(NotificationContext);

  const { webP } = useContext(TransitionContext);

  const desktopScreen = windowSize.orientation === 'landscape' || windowSize.width > 1024;
  const mobileScreen = windowSize.orientation === 'portrait' && windowSize.width <= 1024;
  const phoneScreen = windowSize.orientation === 'portrait' && windowSize.width < 768;

  const containerProps = useSpring({ opacity: show ? 1 : 0, pointerEvents: show ? 'all' : 'none', delay: show ? 0 : 1000 });
  const imgColumnProps = useSpring({ height: show ? windowSize.height : 0, config: { duration: mobileScreen ? 1500 : show ? 1200 : 1500, easing: easeExpInOut, delay: show ? 0 : 700 } });
  const infoColumnProps = useSpring({ height: show ? windowSize.height : 0, config: { duration: show ? 1500 : 1200, easing: easeExpInOut, delay: show ? 700 : 0 } });
  const closeButtonProps = useSpring({ top: show ? 'calc(4vh)' : 'calc(-30vh)', config: { duration: show ? 400 : 200, easing: easeExpInOut, delay: show ? 400 : 0 }});
  const shareButtonProps = useSpring({ top: show ? 'calc(14vh)' : 'calc(-30vh)', config: { duration: show ? 300 : 200, easing: easeExpInOut, delay: show ? 200 : 100 }});

  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedOption, setSelectedOption] = useState(0);

  const [showInfo, setShowInfo] = useState(false);
  const infoOverlayProps = useSpring({ opacity: showInfo ? 1 : 0, pointerEvents: showInfo ? 'all' : 'none' });

  const [showSizeTable, setShowSizeTable] = useState(false);
  const sizeTableOverlayProps = useSpring({ opacity: showSizeTable ? 1 : 0, pointerEvents: showSizeTable ? 'all' : 'none' });

  const currentPathname = useRef();

  useEffect(() => {
    const onPopButton = () => {
      if (showInfo) {
        routerRef.current.history.push(currentPathname.current);
        setShowInfo(false);
      } else if (show) {
        routerRef.current.history.push(currentPathname.current);
        closeOverlay();
      }
    }

    window.addEventListener('popstate', onPopButton);

    return () => window.removeEventListener('popstate', onPopButton);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showInfo, routerRef]);

  useEffect(() => {
    if (show) {
      currentPathname.current = routerRef.current.history.location.pathname;
    }
  }, [show, routerRef]);

  useEffect(() => {
    if (product) {
      set({ y: mHeight * 0.9, config: { ...config.molasses } });

      setSelectedSize(product.sizes[0]);

      const videos = product.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images.filter(image => image.video))}), { images: [] })
    
      if (videos.length > 0) {
        videos.forEach(video => {
          if (document.getElementById(video.name)) document.getElementById(video.name).play();
        });
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const allImages = product ? product.options.reduce((accumulator, current) => ({ images: accumulator.images.concat(current.images)}), { images: [] }) : [];

  const usableImages = product && product.combinedPhotos ? allImages.images.filter(image => image.jpg || image.video) : product ? product.options[selectedOption].images.filter(image => image.jpg || image.video) : [];

  const formatPrice = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " ARS";
    } catch (e) {
      console.log(e)
    }
  };

  const hasNoStock = product => {
    if (!product) return true;
    
    return product.options.every(option => option.sizes.every(size => parseInt(size.stock, 10) === 0));
  };

  const [showPreOrder, setShowPreOrder] = useState(true);
  const preOrderProps = useSpring({ opacity: product && showPreOrder ? 1 : 0, backgroundColor: product && !hasNoStock(product) ? '#a6513f' : '#131414', delay: product && showPreOrder ? 800 : 0 });

  const closeOverlay = () => {
    setBodyLock(false);
    set({ y: mHeight * 1.3, config: { ...config.slow } });
    setShowPreOrder(false);
    setShowProductOverlay(false);

    sleep(1500).then(() => {
      setOverlayProduct(null);
      setShowPreOrder(true);
    })
  }

  const shareProduct = () => {
    const copyToClipboard = text => {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      //dummy.style.display = 'none';
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }

    copyToClipboard("https://verodelacanaltienda.com/share/" + product.id);
    showNotification('Copiado!', 'El link ha sido copiado a tu portapapeles. Ahora puedes enviarselo a quien quieras.', 'success');
  }

  const handleAddToCart = (e) => {
    e.stopPropagation();

    if (!shoppingCart.some(item => item.id === product.id)) {
      setShoppingCart(shoppingCart.concat({ 
        id: product.id, 
        name: product.name, 
        optionID: product.options[selectedOption].id,
        option: product.options[selectedOption].name,
        size: selectedSize,
        price: product.options[selectedOption].offer ? product.options[selectedOption].priceOffer : product.options[selectedOption].price,
        image: usableImages.filter(image => !image.video)[0] || null,
        quantity: 1,
        availableStock: parseInt(product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock, 10),
        weight: product.weight,
        height: product.height,
        length: product.length,
        width: product.width
      }));
    } else {
      closeOverlay();

      sleep(1000).then(() => {
        setCartStatus('full');
      })
    }
  }

  const addButtonProps = useSpring({ width: product && shoppingCart.some(item => item.id === product.id) ? 'calc(0vw)' : desktopScreen ? 'calc(16vw)' : mobileScreen && !phoneScreen ? 'calc(25vw)' : 'calc(60vw)', opacity: product && shoppingCart.some(item => item.id === product.id) ? 0 : 1 });

  const finishButtonProps = useSpring({ opacity: product && shoppingCart.some(item => item.id === product.id) ? 1 : 0 });

  const mobileImg = windowSize.width <= 1024;

  const getImage = image => {
    if (mobileImg && image.mobile) {
      return image.mobile.url;
    } else if (webP === 'webp' && image.webp) {
      return image.webp.url;
    } else if (image.jpg) {
      return image.jpg.url;
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (product) setBodyLock(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <>
      {showInfo && <animated.div style={infoOverlayProps} className="usefulInfoOverlayBg" onClick={() => setShowInfo(false)}/>}
      {showInfo && <animated.div style={infoOverlayProps} className="usefulInfoOverlay">
        <div className="usefulInfoText">
          <p className="usefulInfoTextInner">
            <strong>¿COMO COMPRAR?</strong><br/><br/>

            1) Una vez elegido el color y talle del producto, presionamos “Agregar al Carrito”.

            <br/><br/>2) En ese momento tendremos la opción de “Seguir Comprando” para ver más productos y agregarlos a nuestra orden, o “Pagar ahora”, para continuar con el proceso de pago y checkout de la página. “Seguir Comprando” nos lleva nuevamente a la lista de productos, mientras que “Pagar Ahora” nos lleva al checkout. Presionamos “Pagar Ahora”.

            <br/><br/>3) En la primera pantalla podremos controlar nuestro pedido, aumentar o disminuir la cantidad de cada producto que hayamos seleccionado o remover productos de nuestra orden. Si todo esta OK, presionamos “Siguiente” y pasamos a la sección de “Cuenta”.

            <br/><br/>4) En la sección de “Cuenta”, podemos crear nuestra cuenta o iniciar sesión si ya tenemos una, de forma tal de que nuestros datos de envío queden guardados en nuestro sistema para futuras compras y para poder recibir beneficios en el futuro. Si deseamos crear una cuenta, podemos hacerlo y luego presionar “Siguiente”, o si no deseamos crear una cuenta, podemos presionar “Continuar como Invitado” para seguir con nuestra compra en la sección de “Información”.

            <br/><br/>5) En la sección de “Información” es necesario completar los campos para que la tienda pueda procesar nuestra orden. Nombre, Teléfono e Email son siempre obligatorios, y vale recordar que el Email debe estar siempre bien formateado, así que debemos asegurarnos de ingresarlo correctamente. Por otro lado, los demás campos serán obligatorios si vamos a elegir envío por correo a nuestra dirección ingresada. Si elegiremos la opción “Retiro en Local”, estos campos no son obligatorios. Una vez completados los campos necesarios, presionamos “Siguiente” para ir a confirmar nuestra orden.

            <br/><br/>6) En esta última pantalla, elegimos la opción de envío (ver más sobre esto en “METODOS DE ENVÍO”). Una vez elegida la opción, confirmamos el costo final de la orden y presionamos “Pagar Ahora”. Si hay algún dato obligatorio no ingresado, esta opción estará deshabilitada, por lo cual debemos confirmar de que todos los datos hayan sido ingresados.

            <br/><br/>7) Una vez presionamos “Pagar Ahora”, la página nos llevará a la plataforma de cobro de MercadoPago, donde podremos pagar nuestra orden.

            <br/><br/>8) Por último, si el pago salió bien, el sitio nos llevará a la página de confirmación de orden, donde podremos descargar el comprobante si así quisiéramos y ver que este todo en orden. Recuerden que el comprobante también nos será enviado al email que hayamos ingresado.


            <br/><br/><strong>METODOS DE ENVÍO</strong>

            <br/><br/>- Correo Argentina Encomienda Clásica: Demora entre 3 y 6 días y es la opción de envío más económica. Permite envíos a todo el país.

            <br/><br/>- Correo Argentina Encomienda Prioritaria: Demora entre 1 y 3 días y es la opción de envío más rápida. Permite envíos a todo el país.

            <br/><br/>- Retiro en Local: Esta opción permite, previa coordinación de horario y día con uno de nuestros asesores, retirar la orden en nuestro Atelier en Vicente López 1756.

            <br/><br/><strong>Importante:</strong> dada la situación de público conocimiento, no podemos hacernos responsables por cualquier demora relacionada al envío de los productos, ya que el correo puede o no estar funcionando de manera normal.

            <br/><br/><strong>METODOS DE PAGO</strong>

            <br/><br/>- Tarjetas de crédito: Todas las tarjetas hasta 3 cuotas sin interés y hasta 12/18 cuotas con interés. MercadoPago también ofrece algunas promociones de cuotas sin interés con selectas tarjetas. Para más información sobre esto, visitar <a href="https://www.mercadopago.com.ar/cuotas" target="_blank" rel="noopener noreferrer">https://www.mercadopago.com.ar/cuotas</a>

            <br/><br/>- MercadoPago: Crédito de MercadoPago


            <br/><br/><strong>DEVOLUCIONES/CAMBIOS</strong>

            <br/><br/>Para compras online, se aceptan cambios 30 días corridos desde que se realizó la compra. Asimismo, desde la fecha de recepción del producto tenés 7 días hábiles para comunicarte al número <strong>+54 9 11 2316-6920</strong> en caso de querer realizar un cambio. Caso contrario no se aceptan cambios ni devoluciones, sin excepciones.

            <br/><br/>Para cualquier consulta sobre nuestra política de cambios, comunicarse con uno de nuestros asesores a <a href="mailto:verodelacanaltienda@gmail.com">verodelacanaltienda@gmail.com</a>


            <br/><br/><strong>ATELIER</strong>

            <br/><br/>Es posible visitar nuestro Atelier en Vicente López 1756, siempre previa coordinación de día y horario con uno de nuestros asesores. Para más información y coordinar una visita, contactarse a <a href="mailto:verodelacanaltienda@gmail.com">verodelacanaltienda@gmail.com</a>
          </p>
        </div>
        <div className="usefulInfoCloseButton" onClick={() => setShowInfo(false)}>CERRAR</div>
      </animated.div>}

      {showSizeTable && <animated.div style={sizeTableOverlayProps} className="usefulInfoOverlayBg" onClick={() => setShowSizeTable(false)}/>}
      {showSizeTable && 
        <animated.div style={sizeTableOverlayProps} className="sizeTableInfoOverlay">
          <div className="usefulInfoText" style={{ height: '100%' }}>
            <img src={require('../assets/images/sizeTable.jpg')} alt="Tabla de talles VDLC" className="sizeTableImg" />
          </div>
          <div className="usefulInfoCloseButton" onClick={() => setShowSizeTable(false)}>CERRAR</div>
        </animated.div>
      }

      {product ? <animated.div style={containerProps} className="productOverlayContainer">
        <animated.img style={closeButtonProps} src={require('../assets/images/closeButton.png')} alt="Boton para cerrar la ventana" className="productOverlayClose" onClick={closeOverlay} />
        <animated.img style={shareButtonProps} src={require('../assets/images/shareButton.png')} alt="Boton para compartir el producto" className="productOverlayClose" onClick={shareProduct} />

        <animated.div style={imgColumnProps} className="productOverlayImgContainer">
          {product && !product.history && !hasNoStock(product) && product.category === 'pre order' &&
            <animated.div style={preOrderProps} className="preOrderBannerSingleProduct">
              PRE ORDER
              {/*<p className="preOrderBannerSingleProductSmall">Se despacha el 7 de Agosto</p>*/}
            </animated.div>
          }
          {product && !product.history && hasNoStock(product) &&
            <animated.div style={preOrderProps} className="preOrderBannerSingleProduct">
              AGOTADO
            </animated.div>
          }
          <div style={{ height: windowSize.height }} className="productOverlayImgContent">
            <div className="productOverlayImgInner">
              { usableImages.map(image => !image.video
                ? <img key={image.name} src={getImage(image) || require('../assets/images/homeDecoration.jpg')} alt={product.name} className="productOverlayImg" />
                : <div key={image.name} className="productOverlayVideoContainer">
                    <video autoPlay muted playsInline loop id={image.name} className="productOverlayVideo">
                      <source src={`${image.url}`} type="video/mp4" />
                    </video>
                  </div>
              )}
            </div>
          </div>
        </animated.div>
        <animated.div style={infoColumnProps} className="productOverlayInfoContainer">
          <div style={{ height: windowSize.height }} className="productOverlayInfoContent">
            <div className="productOverlayInfoInner">
              <p className="productOverlayTitle">{product.name}</p>
              <p className="productOverlayDesc">{product.description}</p>
              {!product.history && <p className="productOverlayPrice"><span style={{ display: product.options[selectedOption].offer ? 'flex' : 'none', marginRight: 20 }}>{formatPrice(product.options[selectedOption].priceOffer)}</span><span style={{ textDecoration: product.options[selectedOption].offer ? 'line-through' : 'none', opacity: product.options[selectedOption].offer ? 0.3 : 1 }}>{formatPrice(product.options[selectedOption].price)}</span></p>}
              {!product.history && <div className="productOverlaySizesContainer">
                <p className="productOverlaySizesTitle">Seleccioná tu talle:</p>
                <div className="productOverlaySizesButtons">
                  { product.sizes.map(size =>
                    <animated.div key={size} onClick={() => setSelectedSize(size)} style={{ color: selectedSize === size ? '#666' : '#e1e1e1', borderColor: selectedSize === size ? '#666' : '#e1e1e1' }} className="productOverlaySizesButton">{size}</animated.div>
                  )}
                </div>
              </div>}
              <div className="productOverlaySizesContainer">
                <p className="productOverlaySizesTitle">Seleccioná el color:</p>
                <div className="productOverlaySizesButtons">
                  { product.options.map((option, index) =>
                    <animated.div key={option.colorCode} onClick={() => setSelectedOption(index)} style={{ opacity: selectedOption === index ? 1 : 0.5, borderColor: selectedOption === index ? '#666' : '#e1e1e1', backgroundColor: option.colorCode }} className="productOverlaySizesButton">
                      <div className="productOverlayColorTooltip">{option.name}</div>
                    </animated.div>
                  )}
                </div>
              </div>
              {!product.history && 
                <div className="buttonsContainer">
                  <animated.button style={finishButtonProps} onClick={closeOverlay} className="keepShoppingButton">SEGUIR COMPRANDO</animated.button>
                  <button onClick={product.options[selectedOption].sizes.find(size => size.name === selectedSize) && product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock > 0 ? (e) => handleAddToCart(e) : null} disabled={!product.options[selectedOption].sizes.find(size => size.name === selectedSize) || product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock <= 0} className="addToCartButton">
                    <animated.div style={addButtonProps} className="addToCartTopLayer">
                      <div className="addToCartTopLayerInner">AÑADIR A TU CARRITO</div>
                    </animated.div>
                    <div className="addToCartBottomLayer">PAGAR AHORA</div>
                  </button>
                </div>
              }
              {!product.history && (!product.options[selectedOption].sizes.find(size => size.name === selectedSize) || product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock <= 0) && <p className="singleProductOverlayNoStock">Este producto esta fuera de stock.</p>}
              {!product.history && <div className="extraInfoContainer" onClick={() => setShowInfo(true)}>
                <Info className="extraInfoIcon" />
                <p className="extraInfoText">INFORMACIÓN ÚTIL</p>
              </div>}
              {!product.history && <div className="extraInfoContainer" onClick={() => setShowSizeTable(true)}>
                <Tag className="extraInfoIcon" />
                <p className="extraInfoText">TABLA DE TALLES</p>
              </div>}
            </div>
          </div>
        </animated.div>
        <animated.div {...bind()} style={{ bottom: `calc(-${windowSize.height} + ${mHeight - 100}px)`, y, height: windowSize.height }} className="mobileProductOverlayInfo">
          <div className="mobileProductOverlayButtons">
            <div className="mobileProductButtonsSeparator" />
            <img src={require('../assets/images/closeButton.png')} alt="Boton para cerrar la ventana de producto" className="mobileProductButton" onClick={closeOverlay} />
            <div className="mobileProductButtonsSeparator" />
            <img style={shareButtonProps} src={require('../assets/images/shareButton.png')} alt="Boton para compartir el producto" className="mobileProductButton" onClick={shareProduct} />
          </div>
          <div className="productOverlayInfoContent">
            <div className="productOverlayInfoInner">
              <p className="productOverlayTitle">{product.name}</p>
              <p className="productOverlayDesc">{product.description}</p>
              {!product.history && <p className="productOverlayPrice"><span style={{ textDecoration: product.options[selectedOption].offer ? 'line-through' : 'none', opacity: product.options[selectedOption].offer ? 0.3 : 1 }}>{formatPrice(product.options[selectedOption].price)}</span><span style={{ display: product.options[selectedOption].offer ? 'flex' : 'none', marginLeft: 10 }}>{formatPrice(product.options[selectedOption].priceOffer)}</span></p>}
              {!product.history && <div className="productOverlaySizesContainer">
                <p className="productOverlaySizesTitle">Seleccioná tu talle:</p>
                <div className="productOverlaySizesButtons">
                  { product.sizes.map(size =>
                    <animated.div key={size} onClick={() => setSelectedSize(size)} style={{ color: selectedSize === size ? '#666' : '#e1e1e1', borderColor: selectedSize === size ? '#666' : '#e1e1e1' }} className="productOverlaySizesButton">{size}</animated.div>
                  )}
                </div>
              </div>}
              <div className="productOverlaySizesContainer">
                <p className="productOverlaySizesTitle">Seleccioná el color:</p>
                <div className="productOverlaySizesButtons">
                  { product.options.map((option, index) =>
                    <animated.div key={option.colorCode} onClick={() => setSelectedOption(index)} style={{ opacity: selectedOption === index ? 1 : 0.5, borderColor: selectedOption === index ? '#666' : '#e1e1e1', backgroundColor: option.colorCode }} className="productOverlaySizesButton">
                      <div className="productOverlayColorTooltip">{option.name}</div>
                    </animated.div>
                  )}
                </div>
              </div>
              {!product.history && 
                <div className="buttonsContainer">
                  {shoppingCart.some(item => item.id === product.id) ? <animated.button style={finishButtonProps} onClick={closeOverlay} className="keepShoppingButton">SEGUIR COMPRANDO</animated.button> : null}
                  <button onClick={product.options[selectedOption].sizes.find(size => size.name === selectedSize) && product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock > 0 ? (e) => handleAddToCart(e) : null} disabled={!product.options[selectedOption].sizes.find(size => size.name === selectedSize) || product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock <= 0} className="addToCartButton">
                    <animated.div style={addButtonProps} className="addToCartTopLayer">
                      <div className="addToCartTopLayerInner">AÑADIR A TU CARRITO</div>
                    </animated.div>
                    <div className="addToCartBottomLayer">PAGAR AHORA</div>
                  </button>
                </div>
              }        
              {!product.history && (!product.options[selectedOption].sizes.find(size => size.name === selectedSize) || product.options[selectedOption].sizes.find(size => size.name === selectedSize).stock <= 0) && <p className="singleProductOverlayNoStock">Este producto esta fuera de stock.</p>}
              {!product.history && <div className="extraInfoContainer" onClick={() => setShowInfo(true)}>
                <Info className="extraInfoIcon" />
                <p className="extraInfoText">INFORMACIÓN ÚTIL</p>
              </div>}
              {!product.history && <div className="extraInfoContainer" onClick={() => setShowSizeTable(true)}>
                <Tag className="extraInfoIcon" />
                <p className="extraInfoText">TABLA DE TALLES</p>
              </div>}
            </div>
          </div>
        </animated.div>
      </animated.div> : null}
    </>
  );
}

export { SingleProductOverlay };