import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { easeExpInOut } from 'd3-ease';

import './Preloader.css';

const Preloader = ({ windowSize, setBodyLock, progressLoad }) => {

  const [open, setOpen] = useState(true);
  const preloaderProps = useSpring({ width: open ? 'calc(100vw)' : 'calc(0vw)', config: { duration: 1000, easing: easeExpInOut } });

  const [internetExplorer, setInternetExplorer] = useState(false);

  useEffect(() => {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    
    // IE 11
    //ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    
    // Edge 12 (Spartan)
    //ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    
    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    var trident = ua.indexOf('Trident/');

    if (msie > 0 || trident > 0) {
      // IE 10 or older => return version number
      setInternetExplorer(true);
    } else {
      setInternetExplorer(false);
    }

    /*var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }*/
  }, []);

  useEffect(() => {
    if (progressLoad > 50) {
      setShowEnter(true);
    }
  }, [progressLoad]);

  const [showEnter, setShowEnter] = useState(false);
  const enterProps = useSpring({ marginLeft: !showEnter ? (windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 'calc(-50vw)' : 'calc(-80vw)') : (windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 'calc(-4.5vw)' : 'calc(-12vw)'), config: { duration: 1500, easing: easeExpInOut } });

  const progressMultiplier = windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 15 : 30

  const progressProps = useSpring({
    from: { width: 'calc(0vw)', height: 'calc(0vw)', borderRadius: 'calc(0vw)' },
    to: { width: `calc(${(progressLoad * progressMultiplier) / 100}vw)`, height: `calc(${(progressLoad * progressMultiplier) / 100}vw)`, borderRadius: `calc(${(progressLoad * progressMultiplier) / 200}vw)` },
    config: { duration: 1500, easing: easeExpInOut }
  });

  const navProps = useSpring({ from: { bottom: 'calc(-15vh)' }, to: { bottom: 'calc(0vh)' }, config: { duration: 1500, easing: easeExpInOut } });

  const handleEnter = () => {
    setBodyLock(false);
    setOpen(false);
  }

  useEffect(() => {
    setBodyLock(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div id="preloaderContainer" style={preloaderProps} className="preloaderContainer">
      { !internetExplorer
      ? <>
          <div className="preloaderNav">
            <animated.img style={navProps} src={require('../assets/images/logoVDLC_1.png')} alt="Logo VDLC" className="preloaderLogo" />
            <animated.p style={navProps} className="preloaderYear">{(new Date()).getFullYear()}</animated.p>
          </div>
          <div className="preloaderLoadContainer">
            <animated.div className="preloaderProgress" style={progressProps} />
            <animated.p className="preloaderButton" style={enterProps} onClick={() => handleEnter()}>ENTRAR</animated.p>
          </div>
        </>
      : <p className="internetExplorerWarning">Para utilizar esta página, debes contar con un navegador más moderno. Te sugerimos Google Chrome, Firefox, Safari o Microsoft Edge.</p>
      }
    </animated.div>
  );
};

export { Preloader };