import React, { useContext, useEffect } from 'react';

import { TransitionContext } from '../Context/TransitionContext';

import './NotFound.css';

const NotFound = ({ windowSize, incrementProgressLoad }) => {

  const { setWhiteNav } = useContext(TransitionContext);

  useEffect(() => {
    setWhiteNav(false);
    incrementProgressLoad(100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contactContainer" style={{ height: windowSize.height }}>
      <div className="notFoundContainer">
        <p className="notFoundMainTitle">404</p>
        <p className="notFoundMainSub">Lo sentimos. La página que estás buscando no existe. Por favor, regresa al inicio para continuar disfrutando de nuestros mejores productos.</p>
      </div>
    </div>
  );
};

export default NotFound;