import React from 'react';
import { useSpring, animated } from 'react-spring';
import { easeExpInOut } from 'd3-ease';

import './BubblesTransitionOverlay.css';

const BubblesTransitionOverlay = ({ show, windowSize }) => {

  const leftOverlayProps = useSpring({
    from: { right: '-45%', top: '-110%' },
    to: { right: show ? '160%' : '-45%', top: show ? '190%' : '-110%' },
    config: { duration: 2000, easing: easeExpInOut },
  });

  const rightOverlayProps = useSpring({
    from: { right: '160%', top: '190%' },
    to: { right: show ? '-45%' : '160%', top: show ? '-110%' : '190%' },
    config: { duration: 2000, easing: easeExpInOut },
  });
  
  return (
    <div className="transitionOverlayContainer">
      <animated.div style={rightOverlayProps} className="rightBubble" />
      <animated.div style={leftOverlayProps} className="leftBubble" />
    </div>
  );
}

export { BubblesTransitionOverlay };