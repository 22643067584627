import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { Minus, Plus, ShoppingBag, User, MapPin, Check, Truck } from 'react-feather';

import { SmallTextInput } from './SmallTextInput';

import { ProductContext } from '../Context/ProductContext';
import { UserContext } from '../Context/UserContext';
import { NotificationContext } from '../Context/NotificationContext';

import { errorLog } from '../Helpers/errorLog';

import './CheckoutContainer.css';
import { TransitionContext } from '../Context/TransitionContext';

import { deliveryPrices } from '../data/deliveryPrices';

const provinces = [
  { value: 'buenosAires', label: 'Buenos Aires' },
  { value: 'catamarca', label: 'Catamarca' },
  { value: 'caba', label: 'Ciudad Autónoma de Buenos Aires' },
  { value: 'chaco', label: 'Chaco' },
  { value: 'chubut', label: 'Chubut' },
  { value: 'cordoba', label: 'Córdoba' },
  { value: 'corrientes', label: 'Corrientes' },
  { value: 'entreRios', label: 'Entre Ríos' },
  { value: 'formosa', label: 'Formosa' },
  { value: 'jujuy', label: 'Jujuy' },
  { value: 'laPampa', label: 'La Pampa' },
  { value: 'laRioja', label: 'La Rioja' },
  { value: 'mendoza', label: 'Mendoza' },
  { value: 'misiones', label: 'Misiones' },
  { value: 'neuquen', label: 'Neuquén' },
  { value: 'rioNegro', label: 'Río Negro' },
  { value: 'salta', label: 'Salta' },
  { value: 'sanJuan', label: 'San Juan' },
  { value: 'sanLuis', label: 'San Luis' },
  { value: 'santaCruz', label: 'Santa Cruz' },
  { value: 'santaFe', label: 'Santa Fe' },
  { value: 'santiagoDelEstero', label: 'Santiago del Estero' },
  { value: 'tierraDelFuego', label: 'Tierra del Fuego' },
  { value: 'tucuman', label: 'Tucumán' },
];

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const CheckoutContainer = ({ routerRef, windowSize, cartStatus, setCartStatus }) => {
  const { setBodyLock } = useContext(TransitionContext);

  const { signedIn, setSignedIn } = useContext(UserContext);

  const { showNotification } = useContext(NotificationContext);

  const phoneScreen = windowSize.width < 768 && windowSize.orientation === 'portrait';

  const { shoppingCart, setShoppingCart } = useContext(ProductContext);

  const [activeTab, setActiveTab] = useState(1);

  const currentPathname = useRef();

  useEffect(() => {
    const onPopButton = () => {
      if (cartStatus === 'full') {
        routerRef.current.history.push(currentPathname.current);
        setBodyLock(false);
        setCartStatus(null)
      }
    }

    window.addEventListener('popstate', onPopButton);

    return () => window.removeEventListener('popstate', onPopButton);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStatus, routerRef]);

  useEffect(() => {
    if (cartStatus === 'full') {
      currentPathname.current = routerRef.current.history.location.pathname;
    }
  }, [cartStatus, routerRef]);

  useEffect(() => {
    if (activeTab > 1) {
      document.getElementById("prevButton").style.opacity = 1;
    } else {
      document.getElementById("prevButton").style.opacity = 0;
    }
  }, [activeTab]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [buttonStatus, setButtonStatus] = useState('CREAR CUENTA');

  const [signEmail, setSignEmail] = useState('');
  const [signPassword, setSignPassword] = useState('');
  const [signButtonStatus, setSignButtonStatus] = useState('INICIAR SESIÓN');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [infoEmail, setInfoEmail] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [floor, setFloor] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [local, setLocal] = useState('');
  const [province, setProvince] = useState('');

  const [delivery, setDelivery] = useState(null);
  const deliveryPrice = useRef(0);

  const [payButtonStatus, setPayButtonStatus] = useState('PAGAR AHORA');

  const [createdAccount, setCreatedAccount] = useState(false);
  
  const statusOverlayProps = useSpring({ height: windowSize.height, opacity: payButtonStatus !== 'PAGAR AHORA' ? 1 : 0, pointerEvents: payButtonStatus !== 'PAGAR AHORA' ? 'all' : 'none' });

  useEffect(() => {
    if (signedIn && firebase.auth().currentUser) {
      const firestore = firebase.firestore();

      const docRef = firestore.collection("users").doc(firebase.auth().currentUser.uid);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setName(doc.data().name || '');
            setPhone(doc.data().phone || '');
            setInfoEmail(doc.data().email || '');
            setStreet(doc.data().street || '');
            setStreetNumber(doc.data().streetNumber || '');
            setFloor(doc.data().floor || '');
            setZipCode(doc.data().zipCode || '');
            setLocal(doc.data().local || '');
            setProvince(doc.data().province || '');
          }
        })
        .catch(error => {
          errorLog("Getting user data", { userID: firebase.auth().currentUser.uid }, "get", "CheckoutContainer", error.message);
          showNotification("Error", "Ha ocurrido un error cargando los datos. Por favor, vuelva a intentar.", "error");
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const formatPrice = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") + " ARS";
    } catch (e) {
      console.log(e)
    }
  };

  const subtotal = shoppingCart.reduce((accum, curr) => accum + parseInt(curr.price,10) * curr.quantity, 0);

  const nextTab = () => {
    if (activeTab < 4) {
      setShowMain(false);

      sleep(400).then(() => {
        setActiveTab(a => a + 1);
        setShowMain(true);
      })
    } else if (activeTab === 4) {
      handlePaymentOrder();
    }
  };

  const prevTab = () => {
    if (activeTab > 1) {
      setShowMain(false);

      sleep(400).then(() => {
        setActiveTab(a => a - 1);
        setShowMain(true);
      })
    }
  };

  const goToTab = (index) => {
    setShowMain(false);

    sleep(400).then(() => {
      setActiveTab(index);
      setShowMain(true);
    });
  };

  const [showMain, setShowMain] = useState(true);
  const checkoutMainProps = useSpring({ opacity: showMain ? 1 : 0 });

  const handleRemoveProduct = productID => {
    setShoppingCart(shoppingCart.filter(item => item.id !== productID));
  }

  const handleChangeQuantity = (delta, productID) => {
    if ((delta > 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity < shoppingCart.find(item => item.id === productID).availableStock) || (delta < 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity > 1)) {
      setShoppingCart(shoppingCart.map(item => item.id === productID ? { ...item, quantity: item.quantity + delta } : item));
    } else if (delta > 0 && shoppingCart.find(item => item.id === productID) && shoppingCart.find(item => item.id === productID).quantity === shoppingCart.find(item => item.id === productID).availableStock) {
      showNotification('Lo sentimos', 'No hay suficiente stock para este producto en la cantidad que buscas.', 'error');
    }
  }

  const handleSignIn = () => {
    setSignButtonStatus('INICIANDO SESIÓN...');

    firebase.auth().signInWithEmailAndPassword(signEmail.toLowerCase(), signPassword)
      .then(credential => {
        setSignButtonStatus("INICIAR SESIÓN");
        setActiveTab(a => a + 1);
        setSignedIn(true);
      })
      .catch(error => {
        var errorCode = error.code;
        errorLog("On checkout sign in", "none", "signIn", "CheckoutContainer", error.message);
        //console.log(error);
        
        switch(errorCode) {
          case "auth/invalid-email":
            showNotification("Email Inválido", "La dirección de email es inválida. Por favor, intente nuevamente.", "error");
            break;
          case "auth/user-disabled":
            showNotification("Usuario Inhabilitado", "El usuario ha sido inhabilitado. Contáctanos para más información.", "error");
            break;
          case "auth/user-not-found":
            showNotification("Usuario/Contraseña Incorrectos", "El usuario o la contraseña son incorrectos. Por favor, vuelva a intentar.", "error");
            break;
          case "auth/wrong-password":
            showNotification("Usuario/Contraseña Incorrectos", "El usuario o la contraseña son incorrectos. Por favor, vuelva a intentar.", "error");
            break;
          default:
            showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
          }
      });
  }

  const handleSignUp = () => {
    setButtonStatus("CREANDO...");

    firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
      .then(credential => {
        const firestore = firebase.firestore();

        const docRef = firestore.collection("users").doc(credential.user.uid);

        docRef.set({
          email: email
        }, { merge: true });

        setButtonStatus("CREAR CUENTA");
        setCreatedAccount(true);
        nextTab();
        setSignedIn(true);
      })
      .catch(error =>  {
        //console.log(error);
        var errorCode = error.code;
        setButtonStatus("CREAR CUENTA");
        errorLog("On checkout sign up", "none", "signUp", "CheckoutContainer", error.message);

        switch(errorCode) {
          case "auth/email-already-in-use":
            showNotification("Email en uso", "Ya existe una cuenta con este email. Por favor, intente nuevamente con otro.", "error");
            break;
          case "auth/invalid-email":
            showNotification("Email Inválido", "La dirección de email es inválida. Por favor, intente nuevamente.", "error");
            break;
          case "auth/weak-password":
            showNotification("Contraseña demasiado débil", "La contraseña es demasiado débil. Por favor, intente nuevamente con otra.", "error");
            break;
          default:
            showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
        }
      });
  }

  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        setSignedIn(false);
      })
      .catch(() => {
        showNotification("Error", "Ha ocurrido un error inesperado. Por favor, vuelva a intentar.", "error");
      })
  }

  const handlePaymentOrder = async () => {
    var reg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!reg.test(infoEmail)) {
      showNotification('Email Inválido', 'El formato de la dirección de email es inválido. Revisala y vuelve a intentar.', 'error');
      return false;
    }

    const firestore = firebase.firestore();
    
    if (createdAccount && firebase.auth().currentUser) {
      firestore.collection('users').doc(firebase.auth().currentUser.uid)
        .set({
          name: name,
          phone: phone,
          street: street,
          streetNumber: streetNumber,
          floor: floor,
          zipCode: zipCode,
          local: local,
          province: province
        }, { merge: true });
    };

    const checkStockPromises = shoppingCart.map(async item => {
      return firestore.collection('products').doc(item.id)
        .get()
        .then(doc => {
          if (doc.exists && doc.data().options.find(option => option.id === item.optionID) && doc.data().options.find(option => option.id === item.optionID).sizes.find(size => size.name === item.size)) {
            if (parseInt(doc.data().options.find(option => option.id === item.optionID).sizes.find(size => size.name === item.size).stock, 10) < item.quantity) {
              return false;
            } else {
              return true;
            }
          } else {
            return 'error';
          }
        })
        .catch(error => {
          return 'error';
        })
    });

    const promiseResults = await Promise.all(checkStockPromises);

    if (promiseResults.some(result => result === 'error')) {
      showNotification('Lo sentimos', 'Ha ocurrido un error al procesar tu orden. Por favor, vuelve a intentar.', 'error');
      return false;
    } else if (promiseResults.some(result => !result)) {
      showNotification('Lo sentimos', 'No contamos con el stock para completar tu orden. Por favor, revisa tu orden y vuelve a intentar.', 'error');
      return false;
    }

    setPayButtonStatus('CREANDO ORDEN...');

    var orderNumberRef = firestore.collection("counters").doc("order");

    let orderNumber = 0;

    await firestore.runTransaction(transaction => {
      return transaction.get(orderNumberRef).then(function(doc) {
        if (doc.exists) {
          orderNumber = doc.data().number;
        }

        const newOrderNumber = doc.data().number + 1;
        transaction.update(orderNumberRef, { number: newOrderNumber });
      });
    });

    if (orderNumber !== 0) {
      const now = new Date();

      firestore.collection('orders')
        .add({
          date: now.getTime(),
          client: {
            name: name,
            phone: phone,
            userID: firebase.auth().currentUser ? firebase.auth().currentUser.uid : '',
            email: infoEmail
          },
          address: {
            street: street,
            streetNumber: streetNumber,
            floor: floor,
            zipCode: zipCode,
            local: local,
            province: province
          },
          orderNumber: orderNumber,
          status: 'created',
          items: shoppingCart,
          shipping: { pickup: delivery === 'pickup', standard: delivery === 'standard', cost: deliveryPrice.current }
        })
        .then(doc => {
          setPayButtonStatus('REDIRECCIONANDO...');
  
          return axios({
            method:'post',
            url: "https://us-central1-vdlc-store.cloudfunctions.net/securePayWithMP",
            data: {
              orderID: doc.id,
              name: name.split(' ')[0],
              surname: name.split(' ')[1],
              email: infoEmail,
              phone: parseInt(phone, 10),
              street: street,
              streetNumber: streetNumber,
              zipCode: zipCode,
              items: shoppingCart.map(item => ({
                "id": item.id,
                "title": item.name,
                "optionID": item.optionID,
                "description": item.option + " - Talle: " + item.size,
                "currency_id": "ARS",
                "quantity": item.quantity,
                "unit_price": parseInt(item.price, 10)
              })).concat([{
                "id": "shipping",
                "title": "Costo de Envío",
                "description": delivery === 'pickup' ? "Retiro en local" : "Correo Argentino Encomienda " + delivery === 'standard' ? "Clásica" : "Prioritaria",
                "currency_id": "ARS",
                "quantity": 1,
                "unit_price": deliveryPrice.current
              }])
            }
          })
            .then((response) => {
              setPayButtonStatus('PAGAR AHORA');
              window.location.href = response.data.redirectURL;
            })
            .catch((error) => {
              //console.log(error);
              errorLog("On checkout payment", "none", "Payment", "CheckoutContainer", error.message);
              setPayButtonStatus('PAGAR AHORA');
              showNotification("Error", "Ha ocurrido un error creando la orden. Por favor, vuelva a intentar.", "error");
            });
        })
        .catch(error => {
          errorLog("On checkout payment or order creation", "none", "Payment", "CheckoutContainer", error.message);
          showNotification("Error", "Ha ocurrido un error creando la orden. Por favor, vuelva a intentar.", "error");
        })
    } else {
      errorLog("On order number transaction", "none", "Payment", "CheckoutContainer", "");
      showNotification("Error", "Ha ocurrido un error creando la orden. Por favor, vuelva a intentar.", "error");
      setPayButtonStatus('PAGAR AHORA');
    }
    
  }

  const inputPhone = (phone) => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const getDeliveryTotal = () => {
    if (delivery === null || (delivery !== 'pickup' && province === '')) {
      return '';
    } else if (delivery === 'pickup') {
      deliveryPrice.current = 0;
      return formatPrice(0);
    }

    const realWeight = shoppingCart.reduce((total, item) => total + item.weight * item.quantity, 0);
    const volumeWeight = shoppingCart.reduce((total, item) => total + ((item.height * item.length * item.width) / 6000) * item.quantity, 0);
  
    const weight = Math.max(realWeight, volumeWeight);
    const regional = province === 'buenosAires' || province === 'caba';

    const standardDeliveryPrice = regional
        ? (weight <= 1 ? deliveryPrices.standard.regional.upTo1 : weight <= 5 ? deliveryPrices.standard.regional.upTo5 : weight <= 10 ? deliveryPrices.standard.regional.upTo10 : weight <= 15 ? deliveryPrices.standard.regional.upTo15 : weight <= 20 ? deliveryPrices.standard.regional.upTo20 : weight <= 25 ? deliveryPrices.standard.regional.upTo25 : deliveryPrices.standard.regional.rest)
        : (weight <= 1 ? deliveryPrices.standard.nonRegional.upTo1 : weight <= 5 ? deliveryPrices.standard.nonRegional.upTo5 : weight <= 10 ? deliveryPrices.standard.nonRegional.upTo10 : weight <= 15 ? deliveryPrices.standard.nonRegional.upTo15 : weight <= 20 ? deliveryPrices.standard.nonRegional.upTo20 : weight <= 25 ? deliveryPrices.standard.nonRegional.upTo25 : deliveryPrices.standard.nonRegional.rest);

    const priorityDeliveryPrice = regional
        ? (weight <= 1 ? deliveryPrices.priority.regional.upTo1 : weight <= 5 ? deliveryPrices.priority.regional.upTo5 : weight <= 10 ? deliveryPrices.priority.regional.upTo10 : weight <= 15 ? deliveryPrices.priority.regional.upTo15 : weight <= 20 ? deliveryPrices.priority.regional.upTo20 : weight <= 25 ? deliveryPrices.priority.regional.upTo25 : deliveryPrices.priority.regional.rest)
        : (weight <= 1 ? deliveryPrices.priority.nonRegional.upTo1 : weight <= 5 ? deliveryPrices.priority.nonRegional.upTo5 : weight <= 10 ? deliveryPrices.priority.nonRegional.upTo10 : weight <= 15 ? deliveryPrices.priority.nonRegional.upTo15 : weight <= 20 ? deliveryPrices.priority.nonRegional.upTo20 : weight <= 25 ? deliveryPrices.priority.nonRegional.upTo25 : deliveryPrices.priority.nonRegional.rest);

    if (delivery === 'standard') {
      deliveryPrice.current = standardDeliveryPrice;
      return formatPrice(standardDeliveryPrice);
    } else {
      deliveryPrice.current = priorityDeliveryPrice;
      return formatPrice(priorityDeliveryPrice);
    }
  }

  useEffect(() => {
    if (cartStatus === 'full') setBodyLock(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStatus]);

  return (
    <div className="checkoutContainerInner">
      <animated.div style={statusOverlayProps} className="checkoutStatusOverlay">
        { payButtonStatus === "CREANDO ORDEN..." ? "Solo un momento. Estamos creando su orden." : "Lo estamos redireccionando a la plataforma de pago para completar su pedido." }
      </animated.div>

      <div className="checkoutContainerFirstLine">
        <p className="checkoutContainerPrice">{"SUBTOTAL: " + formatPrice(subtotal)}</p>
        <img onClick={() => { setBodyLock(false); setCartStatus(null); }} src={require('../assets/images/closeButtonCrop.png')} alt="Boton para cerrar el carrito" className="checkoutContainerCloseButton" />
      </div>
      <div className="checkoutContainerNav">
        <div className="checkoutContainerNavLink" onClick={() => goToTab(1)} style={{ color: activeTab === 1 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)' }}>{!phoneScreen ? "CARRITO" : <ShoppingBag className="checkoutMobileTabIcon" />}</div>
        <div className="checkoutContainerNavLink" onClick={() => goToTab(2)} style={{ color: activeTab === 2 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)' }}>{!phoneScreen ? "CUENTA" : <User className="checkoutMobileTabIcon" />}</div>
        <div className="checkoutContainerNavLink" onClick={() => goToTab(3)} style={{ color: activeTab === 3 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)' }}>{!phoneScreen ? "INFORMACIÓN" : <MapPin className="checkoutMobileTabIcon" />}</div>
        <div className="checkoutContainerNavLink" onClick={() => goToTab(4)} style={{ color: activeTab === 4 ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)', borderRight: 'none' }}>{!phoneScreen ? "ENVÍO" : <Truck className="checkoutMobileTabIcon" />}</div>
      </div>
      <animated.div style={checkoutMainProps} className="checkoutMainContainer">
        { activeTab === 1
        ? <div className="checkoutCartContainer">
            { shoppingCart.map((item, index) => 
              <div key={item.id} className="checkoutCartLine">
                <img onClick={() => handleRemoveProduct(shoppingCart[index].id)} src={require('../assets/images/minusButton.png')} alt="Boton para remover producto de la lista" className="checkoutCartRemoveButton" />
                <img src={shoppingCart[index].image.mobile.url || require('../assets/images/homeDecoration.jpg')} alt={shoppingCart[index].name} className="checkoutCartImg" />
                <div className="checkoutLineInfoContainer">
                  <div className="checkoutLineInfoMain">
                    <p className="checkoutLineTitle">{shoppingCart[index].name}</p>
                    <p className="checkoutLineSub">{shoppingCart[index].option}</p>
                  </div>
                  <div className="checkoutLineInfoSec">
                    <div className="checkoutLineInfoSecAux">
                      <div className="checkoutLineInfoSecSize">{"Talle: " + shoppingCart[index].size}</div>
                        <div className="checkoutLineInfoSecQuantity">
                          Cantidad:
                          <div className="checkoutLineInfoSecQuantityContainer">
                            <Minus className="checkoutLineInfoSecQuantityIcon" onClick={() => handleChangeQuantity(-1, shoppingCart[index].id)} />
                            <p className="checkoutLineInfoSecQuantityText">{shoppingCart[index].quantity}</p>
                            <Plus className="checkoutLineInfoSecQuantityIcon" onClick={() => handleChangeQuantity(1, shoppingCart[index].id)}/>
                          </div>
                        </div>
                    </div>
                    <div className="checkoutLineInfoSecPrice">{formatPrice((parseInt(shoppingCart[index].price,10) * shoppingCart[index].quantity)).toString()}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        : activeTab === 2
        ? <div className="checkoutCartContainer">
            {!signedIn ? <div className="checkoutAccountContainer">
              <div className="checkoutAccountMessageContainer">
                <p className="checkoutAccountMessage">Inicia sesión o crea tu cuenta ahora para poder acceder rápidamente a toda tu información, tus pedidos y todos los beneficios que tenemos para darte. <br/><br/>O puedes <strong>Continuar como Invitado</strong> para finalizar tu compra sin crear tu cuenta.</p>
              </div>
              <div className="checkoutSignInContainer" style={{ borderRight: '1px solid rgba(0,0,0,0.03)' }}>
                <p className="checkoutSignInTitle">Inicia Sesión</p>
                <div className="checkoutSignInForm">
                  <SmallTextInput placeholder="Email" value={signEmail} onChange={setSignEmail} color='#fff' />
                  <SmallTextInput password placeholder="Contraseña" value={signPassword} onChange={setSignPassword} color='#fff' />
                  <button disabled={signEmail === '' || signPassword === ''} onClick={() => handleSignIn()} className="signInButton">{signButtonStatus}</button>
                </div>
              </div>
              <div className="checkoutSignInContainer">
                <p className="checkoutSignInTitle" style={{ textAlign: phoneScreen ? 'left' : 'right' }}>Creá tu cuenta ahora</p>
                <div className="checkoutSignInForm">
                  <SmallTextInput placeholder="Email" value={email} onChange={setEmail} color='#fff' />
                  <SmallTextInput password placeholder="Contraseña" value={password} onChange={setPassword} color='#fff' />
                  <SmallTextInput password placeholder="Confirmar Contraseña" value={confirm} onChange={setConfirm} color='#fff' />
                  <button disabled={email === '' || password === '' || password !== confirm} onClick={() => handleSignUp()} className="signInButton">{buttonStatus}</button>
                  <p className="accountPolicyText" style={{ color: '#fff' }}>
                    Al crear la cuenta, te declaras en concordancia con <a className="accountPolicyLink" style={{ color: '#fff' }} href="https://yunta-s3-bucket.s3.us-east-2.amazonaws.com/Poli%CC%81tica+de+Privacidad+-+Tienda+VDLC.pdf" target="_blank" rel="noopener noreferrer">nuestra política de privacidad.</a>
                  </p>
                </div>
              </div>
            </div> 
            : <div className="checkoutAccountSignOutContainer">
                <p className="checkoutAccountSignOutMessage">Has iniciado sesión como:</p>
                <p className="checkoutAccountSignOutEmail">{firebase.auth().currentUser ? firebase.auth().currentUser.email : ""}</p>
                <button onClick={() => handleSignOut()} className="signInButton">CERRAR SESIÓN</button>
              </div>
            }
          </div>
        : activeTab === 3
        ? <div className="checkoutCartContainer">
            <div className="checkoutSignInTitle" style={{ width: phoneScreen ? '95%' : '100%' }}>Completa tus datos para el envío</div>
            <div className="checkoutMainInfoContainer">
              <div className="accountMainInfoColumn">
                <SmallTextInput placeholder="Nombre" value={name} onChange={setName} color='#fff' />
                <SmallTextInput placeholder="Teléfono" value={phone} onChange={inputPhone} color='#fff' />
                <SmallTextInput placeholder="Email" value={infoEmail} onChange={setInfoEmail} color='#fff' />
                <SmallTextInput placeholder="Calle" value={street} onChange={setStreet} color='#fff' />
                <SmallTextInput placeholder="Altura" value={streetNumber} onChange={setStreetNumber} color='#fff' />
              </div>
              <div className="accountMainInfoColumn">
                <SmallTextInput placeholder="Piso/Departamento" value={floor} onChange={setFloor} color='#fff' />
                <SmallTextInput placeholder="Código Postal" value={zipCode} onChange={setZipCode} color='#fff' />
                <SmallTextInput placeholder="Localidad" value={local} onChange={setLocal} color='#fff' />
                <p className="smallInputSimpleLabel" style={{ color: '#fff' }}>Provincia</p>
                <select placeholder="Provincia" value={province} onChange={(e) => setProvince(e.target.value)} className="smallProductSelect">
                  <option key="" value="" disabled className="smallProductSelectOption">Seleccione una provincia</option>
                  {provinces.map(province => 
                    <option key={province.value} value={province.value} className="smallProductSelectOption">{province.label}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        : activeTab === 4
        ? <div className="checkoutCartContainer">
            <div className="checkoutConfirmationContainer">
              <div className="checkoutConfirmationTitleContainer">
                <p className="checkoutSignInTitle">Elegí tu envío</p>
                <div className="checkoutDeliveryLine" onClick={() => setDelivery('standard')}>
                  <div className="checkoutDeliveryCheckbox">
                    <Check style={{ opacity: delivery === 'standard' ? 1 : 0 }} className="checkoutDeliveryCheck" />
                  </div>
                  <p className="checkoutDeliveryInfoContainer">
                    <strong>Correo Argentino Encomienda Clásica</strong><br/>
                    Demora entre 3 y 6 días
                  </p>
                </div>
                <div className="checkoutDeliveryLine" onClick={() => setDelivery('priority')}>
                  <div className="checkoutDeliveryCheckbox">
                    <Check style={{ opacity: delivery === 'priority' ? 1 : 0 }} className="checkoutDeliveryCheck" />
                  </div>
                  <p className="checkoutDeliveryInfoContainer">
                    <strong>Correo Argentino Encomienda Prioritaria</strong><br/>
                    Demora entre 1 y 3 días hábiles
                  </p>
                </div>
                <div className="checkoutDeliveryLine" onClick={() => setDelivery('pickup')}>
                  <div className="checkoutDeliveryCheckbox">
                    <Check style={{ opacity: delivery === 'pickup' ? 1 : 0 }} className="checkoutDeliveryCheck" />
                  </div>
                  <p className="checkoutDeliveryInfoContainer">
                    <strong>Retiro en Atelier</strong><br/>
                    Vicente López 1756
                  </p>
                </div>
                <div className="checkoutDeliveryTotal">
                  {"ENVÍO: " + getDeliveryTotal()}
                </div>
              </div>
              <div className="checkoutConfirmationProductsOuter">
                <div className="checkoutConfirmationProducts" style={{ justifyContent: 'flex-start' }}>
                  { shoppingCart.map((item, index) => 
                    <div key={item.id} className="checkoutConfirmationProductsLine">
                      <p className="checkoutConfirmationProductsQuantity">{item.quantity + "   x "}</p>
                      <div className="checkoutConfirmationProductInfo">
                        <p className="checkoutConfirmationProductTitle">{item.name}</p>
                        <div className="checkoutConfirmationProducExtra">
                          <p className="checkoutConfirmationProductSub">{item.option}</p>
                          <p className="checkoutConfirmationProductSize">{"Talle: " + item.size}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="checkoutConfirmationProductsOuter">
                <div className="checkoutConfirmationProducts">
                  <p className="checkoutConfirmationAddress">
                    <strong>Dirección</strong><br/>
                    <span>{street + " " + streetNumber + " - " + floor + ","}</span><br/>
                    {provinces.find(item => item.value === province) ? local + ", " +  provinces.find(item => item.value === province).label + ", " + zipCode : ''}
                  </p>
                  <p className="checkoutConfirmationPrice">
                    TOTAL:<br/>
                    {formatPrice(subtotal + deliveryPrice.current)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        : null
        }
      </animated.div>
      <div className="checkoutContainerLastLine">
        <animated.button id="prevButton" onClick={() => prevTab()} style={{ color: '#fff', backgroundColor: '#131414', border: '1px solid #fff' }} className="checkoutContainerMainButton">ANTERIOR</animated.button>
        <button className="checkoutContainerMainButton" disabled={activeTab === 4 && (payButtonStatus !== 'PAGAR AHORA' || name === '' || phone === '' || infoEmail === '' || (delivery !== 'pickup' && (street === '' || streetNumber === '' || zipCode === '' || local === '' || province === '')) || shoppingCart.length === 0 || delivery === null)} onClick={() => nextTab()}>{activeTab === 2 && !signedIn ? "CONTINUAR COMO INVITADO" : activeTab === 4 ? payButtonStatus : "SIGUIENTE"}</button>
      </div>
    </div>
  );
};

export { CheckoutContainer };