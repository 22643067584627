import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { TransitionContext } from '../Context/TransitionContext';

import './PaymentFailure.css';

const PaymentFailure = ({ windowSize, incrementProgressLoad }) => {

  const history = useHistory();

  const { setWhiteNav } = useContext(TransitionContext);

  useEffect(() => {
    setWhiteNav(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    incrementProgressLoad(100);

    if (window.location.search.includes('collection_status')) {
      const params = window.location.search.split('&');

      const status = params.find(param => param.startsWith('collection_status')).split('=')[1];

      if (status === 'null') {
        history.replace('/');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contactContainer" style={{ height: windowSize.height }}>
      <div className="notFoundContainer">
        <img src={require('../assets/images/brokenPayment.jpg')} alt="Tarjeta de crédito rota" className="paymentFailureImg" />
        <p className="paymentFailTitle">Lo sentimos</p>
        <p className="notFoundMainSub">No hemos podido procesar tu pago. Por favor, vuelve a intentar o comunícate con nosotros.</p>
      </div>
    </div>
  );
};

export default PaymentFailure;