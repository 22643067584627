import React, { useRef, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSpring, useTrail, useChain, animated } from 'react-spring';
import { easeExpInOut } from 'd3-ease';

import { ProductContext } from '../Context/ProductContext';

import './Menu.css';
import { TransitionContext } from '../Context/TransitionContext';

const Menu = ({ windowSize, menuOpen, setMenuOpen, setNavBg }) => {

  const history = useHistory();

  const { historyCollections } = useContext(ProductContext);

  const { setBodyLock } = useContext(TransitionContext);

  const menuSizeMultiplier = windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 2.5 : windowSize.width > 767 ? 3.5 : 3.9;

  const menuSpringRef = useRef();
  const menuProps = useSpring({
    ref: menuSpringRef,
    from: { width: 0, height: 0, borderRadius: 0 },
    to: { width: menuOpen ? windowSize.width * menuSizeMultiplier : 0, height: menuOpen ? windowSize.width * menuSizeMultiplier : 0, borderRadius: menuOpen ? windowSize.width * (menuSizeMultiplier / 2) : 0 },
    config: { duration: 1500, easing: easeExpInOut }
  });

  const goTo = path => {
    window.scrollTo({ top: 0 });
    setNavBg(false);
    history.push(path);
    setMenuOpen(false);
    setBodyLock(false);
  }

  const items = historyCollections.length > 0 
  ? [{ id: '2', label: 'colección', click: () => goTo('/coleccion') }, { id: '3', label: 'historia', click: () => goTo('/historia') }, { id: '4', label: 'contacto', click: () => goTo('/contacto') }]
  : [{ id: '2', label: 'colección', click: () => goTo('/coleccion') }, /*{ id: '3', label: 'historia', click: () => console.log('HOLA') },*/ { id: '3', label: 'contacto', click: () => goTo('/contacto') }];

  const config = { mass: 5, tension: 2000, friction: 200 };

  const trailRef = useRef();
  const trail = useTrail(items.length, {
    ref: trailRef,
    config,
    opacity: menuOpen ? 1 : 0,
    top: menuOpen ? 0 : 150,
    from: { top: 150 }
  });

  useChain(menuOpen ? [{current: menuSpringRef.current}, trailRef] : [trailRef, menuSpringRef]);

  const extraProps = useSpring({ opacity: menuOpen ? 1 : 0, delay: menuOpen ? 1200 : 0 });

  const regularScreen = windowSize.width > 1024 || windowSize.orientation === 'landscape';
  const phoneScreen = windowSize.width < 768 && windowSize.orientation === 'portrait';

  useEffect(() => {
    if (menuOpen) setBodyLock(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  return (
    <div className="menuOuter" style={{ pointerEvents: menuOpen ? 'all' : 'none', width: windowSize.width }}>
      <animated.div style={menuProps} className="menuContainerBg" />
      <div className="menuContainer" style={{ pointerEvents: menuOpen ? 'all' : 'none', width: windowSize.width, height: windowSize.height }}>
        {trail.map(({ top }, index) => (
          <div className="menuItem" key={items[index].id} onClick={items[index].click} style={{ marginRight: index === 0 ? (windowSize.width <= 812 && windowSize.orientation === 'landscape' ? 400 : regularScreen ? 600 : phoneScreen ? 0 : 350) : index === 2 ? (regularScreen ? 300 : phoneScreen ? 0 : 250) : 0, marginLeft: index === 1 ? (regularScreen ? 300 : phoneScreen ? 0 : 100) : index === 3 ? (regularScreen ? 280 : phoneScreen ? 0 : 160) : 0 }}>
            <animated.div style={{ top }} className="menuText">
              {items[index].label}
              <div className="menuItemHoverLine" />
              <p className="menuItemTextSub">{index + 1}</p>
            </animated.div>
          </div>
        ))}
        <animated.div style={extraProps} className="menuExtraContainer">
          <div className="menuExtraText" onClick={() =>  goTo('/cuenta')}>
            Mi Cuenta
            <div className="menuExtraUnderline" />
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export { Menu };