import React from 'react';

import './SmallTextInput.css';

function SmallTextInput({ placeholder, value, onChange, color, password = false }) {

  return (
    <div className="formInputSimpleContainer">
      <p className="smallInputSimpleLabel" style={{ color: (color || '#333') }}>{placeholder}</p>
      <input type={password ? "password" : "text"} className="smallInputSimple" style={{ color: '#fff', borderColor: 'rgba(255,255,255,0.7)' }} placeholder="" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

export { SmallTextInput };